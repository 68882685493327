import { Observable, of } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Params,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { User } from '@api/models/user.entity';
import { AuthCustomService } from '@bussiness/custom-apis/auth.custom-service';
import {
	TokenRefreshed,
	TokenUser,
} from '@bussiness/custom-models/auth.custom-models';
import { PlcPublicRoute } from '@bussiness/resources/common/routes.constants';
import { AuthFacade } from '@bussiness/store/features/auth/auth-facade';
import { UsersFacade } from '@bussiness/store/features/users/users-facade';
import { LocalStorageEnum } from '@core/services/storage/local-storage.service';

enum PlcTokenStatus {
	Expired,
	Alive,
}

export const canActivateIfUser = (
	childRoute: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
): Observable<boolean> => {
	const authFacade = inject(AuthFacade);
	const router = inject(Router);

	return authFacade.userTokenExpired$.pipe(
		take(1),
		switchMap((expired: boolean) => {
			if (!expired) return of(PlcTokenStatus.Alive);

			return inject(AuthCustomService)
				.refreshAllTokens()
				.pipe(
					map((tokens: TokenRefreshed[]) => {
						const userToken = tokens.find(
							({ type }) => type === LocalStorageEnum.UserToken,
						);
						if (userToken) {
							const { value } = userToken;
							authFacade.refreshUserToken(value as TokenUser);
							return PlcTokenStatus.Alive;
						} else return PlcTokenStatus.Expired;
					}),
				);
		}),
		withLatestFrom(inject(UsersFacade).current$),
		switchMap(([tokenStatus, user]: [PlcTokenStatus, User]) => {
			const queryParams: Params = { redirectTo: state.url };
			if (tokenStatus === PlcTokenStatus.Expired) {
				router.navigate([PlcPublicRoute.NotAuth], { queryParams });
				return of(false);
			} else if (!user) {
				router.navigate([PlcPublicRoute.FastLogin], { queryParams });
				return of(false);
			}

			return of(true);
		}),
	);
};
