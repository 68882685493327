/* eslint-disable @typescript-eslint/no-magic-numbers */
export const Environment = {
	production: false,
	name: 'development',
	oldApiUrl: 'https://dev-pastalacabra-api.onrender.com/api',
	apiUrl: 'https://dev-pastalacabra-api.onrender.com',
	wsUrl: 'https://dev-pastalacabra-api.onrender.com',
	sentryDns:
		'https://81ee9152fa48414b9f395b82fa352f74@o238435.ingest.sentry.io/6781606',
	sentryReplaysSessionSampleRate: 0.1,
};
