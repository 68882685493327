import { Injectable } from '@angular/core';
import { JwtToken } from '@bussiness/custom-models/auth.custom-models';
import {
	LocalStorageEnum,
	LocalStorageService,
} from '@core/services/storage/local-storage.service';

const MULTIPLE_OF_FOUR = 4;
const ONE_SECOND = 1000;

const RSA_ALGORITHM = 'RSA-OAEP';
const RSA_KEY_FORMAT = 'spki';
const RSA_HASH = 'SHA-1';
const RSA_INIT_KEY_DELIMITER = '-----BEGIN PUBLIC KEY-----';
const RSA_END_KEY_DELIMITER = '-----END PUBLIC KEY-----';
const RSA_KEY_USAGE = 'encrypt';

@Injectable()
export class AuthUtil {
	private _publicKey: CryptoKey;

	constructor(private storage: LocalStorageService) {}

	public async setPublicKey(key: string): Promise<void> {
		const keyWithoutDelimiters = key
			.replace(RSA_INIT_KEY_DELIMITER, '')
			.replace(RSA_END_KEY_DELIMITER, '')
			.trimEnd();

		const decodedKeyChars = Array.from(
			atob(keyWithoutDelimiters),
			(value: string) => value.charCodeAt(0),
		);
		const keyBuffer = new Uint8Array(decodedKeyChars);

		this._publicKey = await crypto.subtle.importKey(
			RSA_KEY_FORMAT,
			keyBuffer,
			{ name: RSA_ALGORITHM, hash: RSA_HASH },
			true,
			[RSA_KEY_USAGE],
		);
	}

	public async encrypt(value: string): Promise<string> {
		const encodedValue = new TextEncoder().encode(value);

		const cipherValue = await crypto.subtle.encrypt(
			{ name: RSA_ALGORITHM },
			this._publicKey,
			encodedValue,
		);

		const valueEncrypted = btoa(
			String.fromCharCode(...new Uint8Array(cipherValue)),
		);

		if (!valueEncrypted) throw new Error('Error encrypting value');

		return valueEncrypted as string;
	}

	public getExpiredTokens(): string[] {
		return this.storage
			.getAllTokens()
			.filter(
				(token: string) => token != null && this.checkExpiration(token),
			);
	}

	public isExpired(tokenType: LocalStorageEnum): boolean {
		const token: string = this.storage.get(tokenType) as string;
		return token == null || this.checkExpiration(token);
	}

	public decode<T>(token: string): T {
		const [, payloadB64] = token.split('.');

		let base64 = payloadB64.replace(/-/g, '+').replace(/_/g, '/');
		while (base64.length % MULTIPLE_OF_FOUR) base64 += '=';

		const decoded = atob(base64);

		return JSON.parse(decoded) as T;
	}

	private checkExpiration(token: string): boolean {
		const payload = this.decode(token) as JwtToken;

		const currentTime = Math.floor(Date.now() / ONE_SECOND);

		return payload.exp < currentTime;
	}
}
