import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Preferences } from '@api/models/preferences.entity';
import { UpdatePreferencesRequest } from '@api/models/update-preferences.request';
import { UpdateUserRequest } from '@api/models/update-user.request';
import { User } from '@api/models/user.entity';
import {
	ImagesToonifyFormData,
	ImagesUploadFormData,
} from '@api/services/images.service';
import { PreferencesService } from '@api/services/preferences.service';
import { UsersService } from '@api/services/users.service';
import { ImagesCustomService } from '@bussiness/custom-apis/images.custom-service';
import { PlcPrivateRoute } from '@bussiness/resources/common/routes.constants';
import { ToastActions } from '@bussiness/store/components/toast/toast.actions';
import { UsersSelectors } from '@bussiness/store/features/users/users.selectors';
import { UsersSockets } from '@core/services/sockets/users.sockets';
import { ObjectUtils } from '@core/utils/object.utils';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { DeviceActions, DeviceSetVolumeAction } from '../device/device.actions';
import {
	UsersActions,
	UsersSetFileAction,
	UsersSetLangAction,
	UsersSetThemeAction,
	UsersSetUserAction,
} from './users.actions';

@Injectable()
export class UsersEffects {
	public join$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UsersActions.join),
			concatLatestFrom(() => this.store.select(UsersSelectors.current)),
			exhaustMap(([, user]: [never, User]) => {
				if (user && !user.isAnonymous) this.usersSockets.join(user._id);
				else
					console.warn(
						'[UserEffects] You cannot connect to WSS events. You must login',
					);
				return EMPTY as Observable<Action>;
			}),
		);
	});

	public updateCurrent$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(UsersActions.updateCurrent),
			concatLatestFrom(() => this.store.select(UsersSelectors.current)),
			exhaustMap(
				([{ user }, storedUser]: [
					action: UsersSetUserAction,
					storedUser: User,
				]) => {
					const apiCalls$: Observable<User | Preferences>[] = [];

					const { name, email, preferences } = user;
					const {
						_id,
						name: storedName,
						email: storedEmail,
						preferences: storedPreferences,
					} = storedUser;

					const userIsEdited = !ObjectUtils.valuesAreEqual<User>(
						user,
						storedUser,
						'name',
						'email',
					);

					if (userIsEdited) {
						const body: UpdateUserRequest = {
							name: name ?? storedName,
							email: email ?? storedEmail,
						};

						apiCalls$.push(
							this.usersService.update({ id: _id }, body),
						);
					} else {
						apiCalls$.push(of(storedUser));
					}

					const preferencesWereEdited =
						!ObjectUtils.valuesAreEqual<Preferences>(
							preferences,
							storedPreferences,
							'hideWizard',
							'volume',
							'theme',
						);

					if (preferencesWereEdited) {
						const { hideWizard, volume, theme } = preferences;
						const {
							hideWizard: storedHideWizard,
							volume: storedVolume,
							theme: storedTheme,
						} = storedPreferences;

						const updatePreferencesRequest: UpdatePreferencesRequest =
							{
								hideWizard: hideWizard ?? storedHideWizard,
								volume: volume ?? storedVolume,
								theme: theme ?? storedTheme,
							};
						apiCalls$.push(
							this.preferencesService.update(
								{ id: _id },
								updatePreferencesRequest,
							),
						);
					} else {
						apiCalls$.push(of(storedPreferences));
					}

					return forkJoin(apiCalls$).pipe(
						concatMap(
							([user, preferences]: [User, Preferences]) => [
								UsersActions.setCurrent({
									user: {
										...user,
										preferences: {
											...preferences,
										},
									},
								}),
								UsersActions.updateCurrentSuccess(),
								DeviceActions.setVolume({
									volume: preferences.volume,
								}),
								ToastActions.sendMessage({
									title: this.transService.instant(
										'network.users.success.update.title',
									),
									text: this.transService.instant(
										'network.users.success.update.text',
									),
									mode: 'info',
								}),
							],
						),
						catchError(() =>
							of(
								ToastActions.sendMessage({
									title: this.transService.instant(
										'network.users.error.update.title',
									),
									text: this.transService.instant(
										'network.users.error.update.text',
									),
									mode: 'error',
								}),
							),
						),
					);
				},
			),
		);
	});

	public updateCurrentAvatar$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(UsersActions.updateCurrentAvatar),
			concatLatestFrom(() => this.store.select(UsersSelectors.current)),
			exhaustMap(([{ file }, user]: [UsersSetFileAction, User]) => {
				const { _id } = user;
				const body: ImagesUploadFormData = { file, userId: _id };

				return this.imagesService.upload(body).pipe(
					concatMap((newUserAvatarUrl: string) => [
						UsersActions.setCurrent({
							user: { ...user, avatarUrl: newUserAvatarUrl },
						}),
						ToastActions.sendMessage({
							title: this.transService.instant(
								'network.users.success.update-avatar.title',
							),
							text: this.transService.instant(
								'network.users.success.update-avatar.text',
							),
							mode: 'info',
						}),
					]),
					tap(() =>
						this.router.navigateByUrl(PlcPrivateRoute.Profile),
					),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.users.error.update-avatar.title',
								),
								text: this.transService.instant(
									'network.users.error.update-avatar.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	public toonifyCurrentAvatar$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(UsersActions.toonifyCurrentAvatar),
			exhaustMap(({ file }: UsersSetFileAction) => {
				const body: ImagesToonifyFormData = { file };

				return this.imagesService.toonifyCustom(body).pipe(
					map((file: File) =>
						UsersActions.toonifyCurrentAvatarSuccess({ file }),
					),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.users.error.toonify.title',
								),
								text: this.transService.instant(
									'network.users.error.toonify.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	public setVolume$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(UsersActions.setVolume),
			concatLatestFrom(() => this.store.select(UsersSelectors.current)),
			exhaustMap(([{ volume }, user]: [DeviceSetVolumeAction, User]) => {
				if (!user || user.isAnonymous)
					return of(DeviceActions.setVolume({ volume }));

				const body: UpdatePreferencesRequest = {
					volume,
				};

				return this.preferencesService
					.update({ id: user._id }, body)
					.pipe(
						concatMap((preferences: Preferences) => [
							UsersActions.setCurrent({
								user: {
									...user,
									preferences: {
										...preferences,
									},
								},
							}),
							DeviceActions.setVolume({
								volume: preferences.volume,
							}),
						]),
						catchError(() =>
							of(
								ToastActions.sendMessage({
									title: this.transService.instant(
										'network.users.error.update-preferences.title',
									),
									text: this.transService.instant(
										'network.users.error.update-preferences.text',
									),
									mode: 'error',
								}),
							),
						),
					);
			}),
		);
	});

	public setTheme$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(UsersActions.setTheme),
			concatLatestFrom(() => this.store.select(UsersSelectors.current)),
			exhaustMap(([{ theme }, user]: [UsersSetThemeAction, User]) => {
				if (!user || user.isAnonymous)
					return EMPTY as Observable<Action>;

				const body: UpdatePreferencesRequest = {
					theme,
				};

				return this.preferencesService
					.update({ id: user._id }, body)
					.pipe(
						map((preferences: Preferences) =>
							UsersActions.setCurrent({
								user: {
									...user,
									preferences: {
										...preferences,
									},
								},
							}),
						),
						catchError(() =>
							of(
								ToastActions.sendMessage({
									title: this.transService.instant(
										'network.users.error.update-preferences.title',
									),
									text: this.transService.instant(
										'network.users.error.update-preferences.text',
									),
									mode: 'error',
								}),
							),
						),
					);
			}),
		);
	});

	public setLang$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(UsersActions.setLang),
			concatLatestFrom(() => this.store.select(UsersSelectors.current)),
			exhaustMap(([{ lang }, user]: [UsersSetLangAction, User]) => {
				if (!user || user.isAnonymous)
					return EMPTY as Observable<Action>;

				const body: UpdatePreferencesRequest = {
					lang,
				};

				return this.preferencesService
					.update({ id: user._id }, body)
					.pipe(
						map((preferences: Preferences) =>
							UsersActions.setCurrent({
								user: {
									...user,
									preferences: {
										...preferences,
									},
								},
							}),
						),
						catchError(() =>
							of(
								ToastActions.sendMessage({
									title: this.transService.instant(
										'network.users.error.update-preferences.title',
									),
									text: this.transService.instant(
										'network.users.error.update-preferences.text',
									),
									mode: 'error',
								}),
							),
						),
					);
			}),
		);
	});

	constructor(
		private actions$: Actions,
		private imagesService: ImagesCustomService,
		private store: Store,
		private usersService: UsersService,
		private usersSockets: UsersSockets,
		private preferencesService: PreferencesService,
		private router: Router,
		private transService: TranslateService,
	) {}
}
