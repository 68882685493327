@if (!menuUnfolded) {
	<main
		class="plc-router-container"
		[@plcNavigationAnimation]="getRouterAnimation()"
	>
		<router-outlet></router-outlet>
	</main>
}

<plc-toolbar-desktop
	[userData]="userToolbarData$ | async"
	[entries]="toolbarEntries$ | async"
	[volume]="volume$ | async"
	[theme]="theme$ | async"
	[lang]="lang$ | async"
	(themeChange)="changeTheme($event)"
	(pressOption)="handleMenuActionPress($event)"
	(volumeChange)="changeVolume($event)"
	(langChange)="changeLang($event)"
/>

<plc-toolbar-mobile
	[userData]="userToolbarData$ | async"
	[environment]="environment"
	[year]="year"
	[entries]="toolbarEntries$ | async"
	[volume]="volume$ | async"
	[theme]="theme$ | async"
	[lang]="lang$ | async"
	(pressOption)="handleMenuActionPress($event)"
	(themeChange)="changeTheme($event)"
	(volumeChange)="changeVolume($event)"
	(langChange)="changeLang($event)"
/>

<plc-footer [environment]="environment" [year]="year" />

<plc-toast />

<plc-dialog-wrapper />

@if (wizardConfig$ | async; as config) {
	<plc-wizard [config]="config" (pressSkip)="hideWizard(config.name)" />
}

<!-- Divider -->
@if (displayLoader$ | async) {
	<plc-loader [hints]="hints$ | async" [loadReasons]="loadReasons$ | async" />
}
