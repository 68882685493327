import { EMPTY, Observable } from 'rxjs';
import { concatMap, exhaustMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AlphabetSoup } from '@api/models/alphabet-soup.entity';
import { AlphabetSoupAnswerStatus } from '@api/models/enums';
import { HitAlphabetSoupAnswerRequest } from '@api/models/hit-alphabet-soup-answer.request';
import {
	AlphabetSoupAnswerService,
	AlphabetSoupAnswerUpdatePathVariables,
} from '@api/services/alphabet-soup-answer.service';
import { AlphabetSoupRoundScore } from '@bussiness/custom-models/alphabet-soup-phase.custom-models';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { GamesSelectors } from '../../games.selectors';
import { AlphabetSoupRoundSelectors } from '../alphabet-soup-round/alphabet-soup-round.selectors';
import { AlphabetSoupSelectors } from '../alphabet-soup/alphabet-soup.selectors';
import {
	AlphabetSoupAnswerActions,
	AlphabetSoupAnswerHitAction,
} from './alphabet-soup-answer.actions';

@Injectable()
export class AlphabetSoupAnswerEffects {
	public hit$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AlphabetSoupAnswerActions.hit),
			concatLatestFrom(({ roundId }: AlphabetSoupAnswerHitAction) => [
				this.store.select(GamesSelectors.id),
				this.store.select(AlphabetSoupSelectors.current(roundId)),
				this.store.select(AlphabetSoupRoundSelectors.scores(roundId)),
			]),
			exhaustMap(
				([action, gameId, soup, scores]: [
					AlphabetSoupAnswerHitAction,
					string,
					AlphabetSoup,
					AlphabetSoupRoundScore,
				]) => {
					const path: AlphabetSoupAnswerUpdatePathVariables = {
						gameId,
						roundId: action.roundId,
						soupId: soup._id,
						answerId: action.answerId,
					};

					const body: HitAlphabetSoupAnswerRequest = {
						status: AlphabetSoupAnswerStatus.Hit,
						time: scores.time,
					};

					return this.alphabetSoupAnswerService
						.update(path, body)
						.pipe(concatMap(() => EMPTY as Observable<Action>));
				},
			),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private alphabetSoupAnswerService: AlphabetSoupAnswerService,
	) {}
}
