import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { PlcError } from '@core/models/common/error.entity';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';

import { ComponentsFacade } from './store/components/components-facade';

const TOAST_DISPLAY_DEBOUNCE = 5000;
@Injectable()
export class PlcErrorHandler implements ErrorHandler {
	private _previousError: any;

	constructor(
		private injector: Injector,
		private ngZone: NgZone,
		private transService: TranslateService,
	) {}

	public handleError(error: any): void {
		this.ngZone.run(() => {
			console.error(error);

			if (error.message === this._previousError?.message) return;
			this._previousError = error;

			Sentry.captureException(error.originalError || error);

			let text = 'errors.generic';
			if (error instanceof HttpErrorResponse) text = 'errors.network';
			else if (error instanceof PlcError) text = error.keyOrMessage;

			const componentsFacade = this.injector.get(ComponentsFacade);
			componentsFacade.toastSendMessage({
				title: this.transService.instant('errors.title'),
				text: this.transService.instant(text),
				mode: 'error',
			});

			const timeout = setTimeout(() => {
				this._previousError = null;
				clearTimeout(timeout);
			}, TOAST_DISPLAY_DEBOUNCE);
		});
	}
}
