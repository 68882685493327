import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TokenType } from '@api/models/enums';
import { GamePrivateLoginRequest } from '@api/models/game-private-login.request';
import { GamePublicLoginRequest } from '@api/models/game-public-login.request';
import { PlayerLoginRequest } from '@api/models/player-login.request';
import { PlayerLogoutRequest } from '@api/models/player-logout.request';
import { TokenRefreshRequest } from '@api/models/token-refresh.request';
import { UserAnonymousLoginRequest } from '@api/models/user-anonymous-login.request';
import { UserNormalLoginRequest } from '@api/models/user-normal-login.request';
import { AuthService } from '@api/services/auth.service';
import {
	PlcHttpConfig,
	PlcHttpConfigToken,
} from '@api/services/services.models';
import {
	TokenGame,
	TokenPlayer,
	TokenRefreshed,
	TokenUser,
} from '@bussiness/custom-models/auth.custom-models';
import { AuthUtil } from '@bussiness/utils/auth.util';
import {
	LocalStorageEnum,
	LocalStorageService,
} from '@core/services/storage/local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class AuthCustomService extends AuthService {
	constructor(
		@Inject(PlcHttpConfigToken) config: PlcHttpConfig,
		http: HttpClient,
		private storage: LocalStorageService,
		private authUtil: AuthUtil,
	) {
		super(config, http);
	}

	public getPublicKey(): Observable<string> {
		return super
			.getKey()
			.pipe(tap((key: string) => this.authUtil.setPublicKey(key)));
	}

	public userLogin(
		body: UserNormalLoginRequest | UserAnonymousLoginRequest,
	): Observable<TokenUser> {
		return super.userLoginV3(body).pipe(
			tap((token: string) =>
				this.storage.save(LocalStorageEnum.UserToken, token),
			),
			map((token: string) => this.authUtil.decode<TokenUser>(token)),
		);
	}

	public gameLogin(
		body: GamePrivateLoginRequest | GamePublicLoginRequest,
	): Observable<TokenGame> {
		return super.gameLoginV3(body).pipe(
			tap((token: string) =>
				this.storage.save(LocalStorageEnum.GameToken, token),
			),
			map((token: string) => this.authUtil.decode<TokenGame>(token)),
		);
	}

	public playerLoginCustom(
		body: PlayerLoginRequest,
	): Observable<TokenPlayer> {
		return super.playerLoginV3(body).pipe(
			tap((token: string) =>
				this.storage.save(LocalStorageEnum.PlayerToken, token),
			),
			map((token: string) => this.authUtil.decode<TokenPlayer>(token)),
		);
	}

	public playerLogoutCustom(body: PlayerLogoutRequest): Observable<void> {
		return super
			.playerLogoutV3(body)
			.pipe(tap(() => this.storage.remove(LocalStorageEnum.PlayerToken)));
	}

	public refreshAllTokens(): Observable<TokenRefreshed[]> {
		const userToken = this.storage.get(
			LocalStorageEnum.UserToken,
		) as string;
		const gameToken = this.storage.get(
			LocalStorageEnum.GameToken,
		) as string;
		const playerToken = this.storage.get(
			LocalStorageEnum.PlayerToken,
		) as string;

		const body: TokenRefreshRequest[] = [];
		if (userToken && this.authUtil.isExpired(LocalStorageEnum.UserToken))
			body.push({
				type: TokenType.UserToken,
				value: userToken,
			});
		if (gameToken && this.authUtil.isExpired(LocalStorageEnum.GameToken))
			body.push({
				type: TokenType.GameToken,
				value: gameToken,
			});
		if (
			playerToken &&
			this.authUtil.isExpired(LocalStorageEnum.PlayerToken)
		)
			body.push({
				type: TokenType.PlayerToken,
				value: playerToken,
			});

		return super.refreshTokens(body).pipe(
			tap((tokens: TokenRefreshRequest[]) => {
				for (const { type, value } of tokens)
					this.storage.save(
						type as unknown as LocalStorageEnum,
						value,
					);
			}),
			map((tokens: TokenRefreshRequest[]) =>
				tokens.map(({ type, value }) => {
					let decodedToken: TokenUser | TokenGame | TokenPlayer;

					if (type === TokenType.UserToken)
						decodedToken = this.authUtil.decode<TokenUser>(value);
					else if (type === TokenType.GameToken)
						decodedToken = this.authUtil.decode<TokenGame>(value);
					else if (type === TokenType.PlayerToken)
						decodedToken = this.authUtil.decode<TokenPlayer>(value);

					return {
						type: type as unknown as LocalStorageEnum,
						value: decodedToken,
					};
				}),
			),
		);
	}
}
