import { firstValueFrom, of } from 'rxjs';

import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ProfileEditForms } from '@bussiness/forms/profile-edit.forms';
import { ResetPasswordForms } from '@bussiness/forms/reset-password.forms';
import { SignInForms } from '@bussiness/forms/sign-in.forms';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { PlcErrorHandler } from './error.handler';
import { FastLoginForms } from './forms/fast-login.forms';
import { LoginForms } from './forms/log-in.forms';
import { NewGameForms } from './forms/new-game.forms';
import { ExpiredTokenInterceptor } from './interceptors/expired-token.interceptor';
import { GameTokenInterceptor } from './interceptors/game-token.interceptor';
import { LoadReasonInterceptor } from './interceptors/load-reason.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { PlayerTokenInterceptor } from './interceptors/player-token.interceptor';
import { RsaInterceptor } from './interceptors/rsa.interceptor';
import { UserTokenInterceptor } from './interceptors/user-token.interceptor';
import { ComponentsFacade } from './store/components/components-facade';
import { AppFacade } from './store/features/app/app.facade';
import { AuthFacade } from './store/features/auth/auth-facade';
import { DeviceFacade } from './store/features/device/device.facade';
import { AlphabetSoupAnswerFacade } from './store/features/games/alphabet-soup-phase/alphabet-soup-answer/alphabet-soup-answer.facade';
import { AlphabetSoupPhaseFacade } from './store/features/games/alphabet-soup-phase/alphabet-soup-phase.facade';
import { AlphabetSoupRoundFacade } from './store/features/games/alphabet-soup-phase/alphabet-soup-round/alphabet-soup-round.facade';
import { AlphabetSoupFacade } from './store/features/games/alphabet-soup-phase/alphabet-soup/alphabet-soup.facade';
import { BagelQuestionFacade } from './store/features/games/bagels-phase/bagel-question/bagel-question.facade';
import { BagelsPhaseFacade } from './store/features/games/bagels-phase/bagels-phase.facade';
import { GamesFacade } from './store/features/games/games.facade';
import { MemoryCellFacade } from './store/features/games/memory-phase/memory-cell/memory-cell.facade';
import { MemoryGridFacade } from './store/features/games/memory-phase/memory-grid/memory-grid.facade';
import { MemoryPhaseFacade } from './store/features/games/memory-phase/memory-phase.facade';
import { PlayersFacade } from './store/features/games/players/players.facade';
import { SongClueFacade } from './store/features/games/song-phase/song-clue/song-clue.facade';
import { SongPhaseFacade } from './store/features/games/song-phase/song-phase.facade';
import { SongRoundFacade } from './store/features/games/song-phase/song-round/song-round.facade';
import { HintsFacade } from './store/features/hints/hints.facade';
import { UsersFacade } from './store/features/users/users-facade';
import { AuthUtil } from './utils/auth.util';

@NgModule({
	declarations: [],
	imports: [TranslateModule.forChild({ extend: true })],
	exports: [],
	providers: [
		UsersFacade,
		AuthFacade,
		GamesFacade,
		PlayersFacade,
		ComponentsFacade,
		BagelsPhaseFacade,
		BagelQuestionFacade,
		MemoryPhaseFacade,
		MemoryGridFacade,
		MemoryCellFacade,
		SongPhaseFacade,
		HintsFacade,
		SongRoundFacade,
		SongClueFacade,
		AlphabetSoupPhaseFacade,
		AlphabetSoupRoundFacade,
		AlphabetSoupFacade,
		AlphabetSoupAnswerFacade,
		DeviceFacade,
		AppFacade,
		{
			provide: AuthUtil,
			deps: [LocalStorageService],
		},
		LoginForms,
		SignInForms,
		FastLoginForms,
		ResetPasswordForms,
		NewGameForms,
		{
			provide: ProfileEditForms,
			deps: [UsersFacade],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ExpiredTokenInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RsaInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoadReasonInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UserTokenInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: GameTokenInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: PlayerTokenInterceptor,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useClass: PlcErrorHandler,
		},
	],
})
export class BussinessModule {}

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}

export function plcAppInitializerFactory(): () => Promise<void> {
	return () => firstValueFrom(of(void 0));
}
