import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Hint } from '@api/models/hint.entity';
import { Store } from '@ngrx/store';

import { HintsSelectors } from './hints.selectors';

@Injectable()
export class HintsFacade {
	public all$: Observable<Hint[]> = this.store.select(HintsSelectors.all);

	constructor(private store: Store) {}
}
