import { Hint } from '@api/models/hint.entity';
import { Action, createReducer, on } from '@ngrx/store';

import { HintsActions, HintsLoadSuccessAction } from './hints.actions';

export const hintsFeatureKey = 'hints';

export interface HintsState {
	list: Hint[];
}

export const initialState: HintsState = {
	list: [],
};

const loadSuccessFn = (
	state: HintsState,
	action: HintsLoadSuccessAction,
): HintsState => {
	return {
		...state,
		list: action.hints,
	};
};

export const reducer = createReducer(
	initialState,
	on(HintsActions.loadSuccess, loadSuccessFn),
);

export function hintsReducer(state = initialState, action: Action): HintsState {
	return reducer(state, action);
}
