import { EMPTY, Observable } from 'rxjs';
import { concatMap, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { BagelPhaseV3RequestType } from '@api/models/enums';
import { FixBagelV3Request } from '@api/models/fix-bagel-v3.request';
import { UpdateBagelV3Request } from '@api/models/update-bagel-v3.request';
import {
	BagelPhaseService,
	BagelPhaseUpdatePathVariables,
} from '@api/services/bagel-phase.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { GamesSelectors } from '../games.selectors';
import {
	BagelPhaseActions,
	BagelPhaseFixAction,
	BagelPhaseTogglePlayingAction,
} from './bagels-phase.actions';
import { BagelsPhaseScores } from './bagels-phase.facade';
import { BagelsPhaseSelectors } from './bagels-phase.selectors';

@Injectable()
export class BagelsPhaseEffects {
	public togglePlaying$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(BagelPhaseActions.togglePlaying),
			concatLatestFrom(({ id }: BagelPhaseTogglePlayingAction) => [
				this.store.select(BagelsPhaseSelectors.scores(id)),
				this.store.select(GamesSelectors.id),
			]),
			exhaustMap(
				([{ id, playing }, { time }, gameId]: [
					BagelPhaseTogglePlayingAction,
					BagelsPhaseScores,
					string,
				]) => {
					const body: UpdateBagelV3Request = {
						_type: BagelPhaseV3RequestType.Update,
						playing,
						time,
					};
					const pathVariables: BagelPhaseUpdatePathVariables = {
						gameId,
						id,
					};

					return this.bagelPhaseService
						.updateV3(pathVariables, body)
						.pipe(
							map(({ inGameQuestion }) =>
								BagelPhaseActions.updateSuccess({
									id,
									inGameQuestion: inGameQuestion as string,
									playing,
								}),
							),
						);
				},
			),
		);
	});

	public fix$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(BagelPhaseActions.fix),
			concatLatestFrom(() => [this.store.select(GamesSelectors.id)]),
			exhaustMap(
				([{ bagelId, bagelQuestion, changeTurn }, gameId]: [
					BagelPhaseFixAction,
					string,
				]) => {
					const { _id, status } = bagelQuestion;

					const body: FixBagelV3Request = {
						_type: BagelPhaseV3RequestType.Fix,
						bagelQuestionId: _id,
						bagelQuestionStatus: status,
						changeTurn,
					};

					const pathVariables: BagelPhaseUpdatePathVariables = {
						gameId,
						id: bagelId,
					};

					return this.bagelPhaseService
						.updateV3(pathVariables, body)
						.pipe(concatMap(() => EMPTY as Observable<Action>));
				},
			),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private bagelPhaseService: BagelPhaseService,
	) {}
}
