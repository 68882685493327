import { from, Observable, ObservableInput } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getEncryptMetadata } from '@api/models/base.models';
import { AuthUtil } from '@bussiness/utils/auth.util';

@Injectable()
export class RsaInterceptor implements HttpInterceptor {
	constructor(private authUtil: AuthUtil) {}

	public intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		const { body } = request;

		if (!body) return next.handle(request);

		const encryptPromises: Promise<void>[] = [];

		for (const [key, value] of Object.entries(request.body)) {
			const valueHasToBeEncrypted = getEncryptMetadata(
				request.body as object,
				key,
			);

			if (valueHasToBeEncrypted && value) {
				const encryptPromise = this.authUtil
					.encrypt(value)
					.then((encryptedValue) => {
						request.body[key] = encryptedValue;
					});
				encryptPromises.push(encryptPromise);
			}
		}

		return from(Promise.all(encryptPromises)).pipe(
			mergeMap(
				() =>
					next.handle(request) as ObservableInput<HttpEvent<unknown>>,
			),
		);
	}
}
