import { Observable, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, take, tap } from 'rxjs/operators';

import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthCustomService } from '@bussiness/custom-apis/auth.custom-service';
import { TokenRefreshed } from '@bussiness/custom-models/auth.custom-models';
import { ComponentsFacade } from '@bussiness/store/components/components-facade';
import { AuthFacade } from '@bussiness/store/features/auth/auth-facade';
import { AuthUtil } from '@bussiness/utils/auth.util';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

const URL_PATTERNS_TO_IGNORE = [
	'auth/user-login',
	'auth/token-refresh',
	'assets',
];

@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {
	private _refreshInProgress: boolean;
	private _refreshCompleted$: Subject<void> = new Subject();

	constructor(
		private storage: LocalStorageService,
		private authFacade: AuthFacade,
		private componentsFacade: ComponentsFacade,
		private authUtil: AuthUtil,
		private authCustomService: AuthCustomService,
		private transService: TranslateService,
	) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		const urlMustBeIgnored = URL_PATTERNS_TO_IGNORE.some((pattern) =>
			request.url.includes(pattern),
		);

		if (urlMustBeIgnored) return next.handle(request);

		const allTokens = this.storage.getAllTokens();

		if (allTokens.length === 0) {
			return next.handle(request);
		} else if (!this._refreshInProgress) {
			const tokensExpired = this.authUtil.getExpiredTokens();

			if (tokensExpired.length === 0) {
				this._refreshInProgress = false;
				return next.handle(request);
			} else {
				this._refreshInProgress = true;

				return this.authCustomService.refreshAllTokens().pipe(
					tap((tokens: TokenRefreshed[]) =>
						this.authFacade.refreshTokensSuccess(tokens),
					),
					mergeMap(() => next.handle(request)),
					catchError(() => {
						this.componentsFacade.toastSendMessage({
							title: this.transService.instant(
								'network.auth.error.token-refresh.title',
							),
							text: this.transService.instant(
								'network.auth.error.token-refresh.text',
							),
							mode: 'warn',
						});
						return next.handle(request);
					}),
					finalize(() => {
						this.componentsFacade.loaderToggle(false);
						this._refreshInProgress = false;
						this._refreshCompleted$.next();
					}),
				);
			}
		} else {
			return this._refreshCompleted$.pipe(
				take(1),
				mergeMap(() => next.handle(request)),
				finalize(() => {
					this._refreshInProgress = false;
					this.componentsFacade.loaderToggle(false);
				}),
			);
		}
	}
}
