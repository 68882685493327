import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, exhaustMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoryCell } from '@api/models/memory-cell.entity';
import { UpdateMemoryCellRequest } from '@api/models/update-memory-cell.request';
import {
	MemoryCellService,
	MemoryCellUpdatePathVariables,
} from '@api/services/memory-cell.service';
import { ToastActions } from '@bussiness/store/components/toast/toast.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { GamesSelectors } from '../../games.selectors';
import { MemoryGridSelectors } from '../memory-grid/memory-grid.selectors';
import {
	MemoryCellActions,
	MemoryCellUpdateAction,
} from './memory-cell.actions';
import { MemoryCellSelectors } from './memory-cell.selectors';

@Injectable()
export class MemoryCellEffects {
	public update$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MemoryCellActions.update),
			concatLatestFrom(({ gridId }: MemoryCellUpdateAction) => [
				this.store.select(GamesSelectors.id),
				this.store.select(MemoryGridSelectors.time(gridId)),
				this.store.select(MemoryCellSelectors.current(gridId)),
			]),
			exhaustMap(
				([action, gameId, time, currentCell]: [
					MemoryCellUpdateAction,
					string,
					number,
					MemoryCell,
				]) => {
					const { gridId, answerKind } = action;

					const pathVariables: MemoryCellUpdatePathVariables = {
						gameId,
						gridId,
						cellId: currentCell._id,
					};

					const body: UpdateMemoryCellRequest = {
						hitted: answerKind === 'hit',
						time,
					};

					return this.memoryCellService
						.update(pathVariables, body)
						.pipe(
							concatMap(() => EMPTY as Observable<Action>),
							catchError(() =>
								of(
									ToastActions.sendMessage({
										title: this.transService.instant(
											'network.memory-phase.error.update-cell.title',
										),
										text: this.transService.instant(
											'network.memory-phase.error.update-cell.text',
										),
										mode: 'error',
									}),
								),
							),
						);
				},
			),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private memoryCellService: MemoryCellService,
		private transService: TranslateService,
	) {}
}
