import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Player } from '@api/models/player.entity';
import { ReadyPlayerV3Request } from '@api/models/ready-player-v3.request';
import { User } from '@api/models/user.entity';
import {
	PlayersService,
	PlayersUpdatePathVariables,
} from '@api/services/players.service';
import { ToastActions } from '@bussiness/store/components/toast/toast.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { UsersSelectors } from '../../users/users.selectors';
import { GamesSelectors } from '../games.selectors';
import { PlayersActions, PlayersToggleReadyAction } from './players.actions';
import { PlayersSelectors } from './players.selectors';

@Injectable()
export class PlayersEffects {
	public toggleReady$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(PlayersActions.toggleReady),
			concatLatestFrom(() => [
				this.store.select(PlayersSelectors.all),
				this.store.select(UsersSelectors.currentId),
				this.store.select(GamesSelectors.id),
			]),
			exhaustMap(
				([{ ready }, players, userId, gameId]: [
					PlayersToggleReadyAction,
					Player[],
					string,
					string,
				]) => {
					const chosenPlayer = players.find(
						({ user }) => user && (user as User)._id === userId,
					);
					const { _id: playerId } = chosenPlayer;

					const body: ReadyPlayerV3Request = {
						ready,
					};

					const pathVariables: PlayersUpdatePathVariables = {
						gameId,
						id: playerId,
					};

					return this.playersService
						.updateV3(pathVariables, body)
						.pipe(
							map((updatedPlayer: Player) =>
								PlayersActions.updateSuccess({
									player: updatedPlayer,
								}),
							),
							catchError(() =>
								of(
									ToastActions.sendMessage({
										title: this.transService.instant(
											'network.players.error.update.title',
										),
										text: this.transService.instant(
											'network.players.error.update.text',
										),
										mode: 'error',
									}),
								),
							),
						);
				},
			),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private playersService: PlayersService,
		private transService: TranslateService,
	) {}
}
