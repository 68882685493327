import {
	PlcToolbarAction,
	PlcToolbarEntry,
} from '@components/modules/layout/toolbar/toolbar.models';

export const LOGGED_TOOLBAR_ENTRIES: PlcToolbarEntry<PlcToolbarAction>[] = [
	{
		icon: 'home',
		action: 'home',
		name: 'components.layout.toolbars.home',
	},
	{
		icon: 'add',
		action: 'new-game',
		name: 'components.layout.toolbars.create-game',
	},
	{
		icon: 'search',
		action: 'search-game',
		name: 'components.layout.toolbars.search-game',
	},
	{
		icon: 'avatar',
		action: 'profile',
		name: 'components.layout.toolbars.profile',
	},
	{
		icon: 'gear',
		action: 'settings',
		name: 'components.layout.toolbars.settings',
	},
	{
		icon: 'info-2',
		action: 'about',
		name: 'components.layout.toolbars.app-info',
	},
	{
		icon: 'logout',
		action: 'logout',
		name: 'components.layout.toolbars.logout',
	},
];

export const NOT_LOGGED_TOOLBAR_ENTRIES: PlcToolbarEntry<PlcToolbarAction>[] = [
	{
		icon: 'home',
		action: 'home',
		name: 'components.layout.toolbars.home',
	},
	{
		icon: 'add',
		action: 'new-game',
		name: 'components.layout.toolbars.create-game',
	},
	{
		icon: 'search',
		action: 'search-game',
		name: 'components.layout.toolbars.search-game',
	},
	{
		icon: 'user',
		action: 'sign-in',
		name: 'components.layout.toolbars.sign-in',
	},
	{
		icon: 'key',
		action: 'log-in',
		name: 'components.layout.toolbars.login',
	},
	{
		icon: 'info-2',
		action: 'about',
		name: 'components.layout.toolbars.app-info',
	},
];

export const TOOLBAR_MAXIMIZE_ENTRY: PlcToolbarEntry<PlcToolbarAction> = {
	icon: 'maximize',
	action: 'fullscreen',
	name: 'components.layout.toolbars.maximize',
};

export const TOOLBAR_MINIMIZE_ENTRY: PlcToolbarEntry<PlcToolbarAction> = {
	icon: 'minimize',
	action: 'exit-fullscreen',
	name: 'components.layout.toolbars.minimize',
};
