import { EMPTY, Observable } from 'rxjs';
import { concatMap, exhaustMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { UpdateAlphabetSoupRoundRequest } from '@api/models/update-alphabet-soup-round.request';
import {
	AlphabetSoupRoundService,
	AlphabetSoupRoundUpdatePathVariables,
} from '@api/services/alphabet-soup-round.service';
import { AlphabetSoupRoundScore } from '@bussiness/custom-models/alphabet-soup-phase.custom-models';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { GamesSelectors } from '../../games.selectors';
import {
	AlphabetSoupRoundActions,
	AlphabetSoupRoundTooglePlayingAction,
} from './alphabet-soup-round.actions';
import { AlphabetSoupRoundSelectors } from './alphabet-soup-round.selectors';

@Injectable()
export class AlphabetSoupRoundEffects {
	public togglePlaying$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AlphabetSoupRoundActions.togglePlaying),
			concatLatestFrom(({ roundId }) => [
				this.store.select(AlphabetSoupRoundSelectors.scores(roundId)),
				this.store.select(GamesSelectors.id),
			]),
			exhaustMap(
				([action, scores, gameId]: [
					AlphabetSoupRoundTooglePlayingAction,
					AlphabetSoupRoundScore,
					string,
				]) => {
					const { roundId, play, timeOut } = action;

					const path: AlphabetSoupRoundUpdatePathVariables = {
						gameId,
						roundId,
					};

					const body: UpdateAlphabetSoupRoundRequest = {
						time: timeOut ? 0 : scores.time,
						playing: play,
					};

					return this.alphabetSoupRoundService
						.update(path, body)
						.pipe(concatMap(() => EMPTY as Observable<Action>));
				},
			),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private alphabetSoupRoundService: AlphabetSoupRoundService,
	) {}
}
