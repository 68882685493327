import { Observable, timer } from 'rxjs';
import { filter, finalize, take, tap } from 'rxjs/operators';

import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentsFacade } from '@bussiness/store/components/components-facade';

const LOADER_DEBOUNCE = 400;

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
	private _pendingRequests = 0;

	constructor(private componentsFacade: ComponentsFacade) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		if (this._pendingRequests == 0) {
			timer(LOADER_DEBOUNCE)
				.pipe(
					take(1),
					filter(() => this._pendingRequests > 0),
					tap(() => this.componentsFacade.loaderToggle(true)),
				)
				.subscribe();
		}

		this._pendingRequests++;

		return next.handle(request).pipe(
			finalize(() => {
				if (this._pendingRequests > 0) this._pendingRequests--;

				if (this._pendingRequests == 0)
					this.componentsFacade.loaderToggle(false);
			}),
		);
	}
}
