import { inject } from '@angular/core';
import {
	SessionStorageKey,
	SessionStorageService,
} from '@core/services/storage/session-storage.service';
import { Action, ActionReducer, INIT } from '@ngrx/store';

import { AppState } from './';
import { WizardState } from './components/wizard/wizard.reducer';
import { AuthState } from './features/auth/auth.reducer';
import { GamesState } from './features/games/games.reducer';
import { UsersState } from './features/users/users.reducer';

type SyncedState = UsersState & AuthState & WizardState & GamesState;
type StateToSync = UsersState | AuthState | WizardState | GamesState;

export const plcStorageSyncReducer = (
	reducer: ActionReducer<AppState>,
): ActionReducer<AppState> => {
	const sessionStorage = inject(SessionStorageService);

	const storageKeysToSync: SessionStorageKey[] = [
		SessionStorageKey.Users,
		SessionStorageKey.Auth,
		SessionStorageKey.Wizard,
		SessionStorageKey.Games,
	];

	return (initialState: AppState | undefined, action: Action) => {
		if (action.type === INIT) {
			const storedState: Partial<AppState> = {};
			storageKeysToSync.forEach((key) => {
				const storedValue = sessionStorage.get<SyncedState>(key);
				if (storedValue) storedState[key] = storedValue;
			});

			initialState = { ...initialState, ...storedState };
		}

		const nextState = reducer(initialState, action);

		storageKeysToSync.forEach((key) => {
			if (nextState[key] === initialState?.[key])
				sessionStorage.save<StateToSync>(key, nextState[key]);
		});

		return nextState;
	};
};
