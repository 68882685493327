import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, exhaustMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
	MemoryGridStatus,
	UpdateMemoryGridRequestType,
} from '@api/models/enums';
import { FixMemoryGridRequest } from '@api/models/fix-memory-grid.request';
import { FlipMemoryGridRequest } from '@api/models/flip-memory-grid.request';
import { MemoryGrid } from '@api/models/memory-grid.entity';
import { ToggleMemoryGridRequest } from '@api/models/toggle-memory-grid.request';
import {
	MemoryGridService,
	MemoryGridUpdatePathVariables,
} from '@api/services/memory-grid.service';
import { ToastActions } from '@bussiness/store/components/toast/toast.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { GamesSelectors } from '../../games.selectors';
import {
	MemoryGridActions,
	MemoryGridFixAction,
	MemoryGridFlipAction,
	MemoryGridTogglePlayingAction,
} from './memory-grid.actions';
import { MemoryGridFacade } from './memory-grid.facade';
import { MemoryGridSelectors } from './memory-grid.selectors';

@Injectable()
export class MemoryGridEffects {
	public flip$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MemoryGridActions.flip),
			concatLatestFrom(() => this.store.select(GamesSelectors.id)),
			exhaustMap(([action, gameId]: [MemoryGridFlipAction, string]) => {
				const { gridId, status } = action;

				const pathVariables: MemoryGridUpdatePathVariables = {
					gameId,
					memoryGridId: gridId,
				};

				const body: FlipMemoryGridRequest = {
					_type: UpdateMemoryGridRequestType.Flip,
					status,
				};

				return this.memoryGridService.update(pathVariables, body).pipe(
					concatMap(({ status }: MemoryGrid) => {
						if (status === MemoryGridStatus.ReadyForPlaying)
							this.memoryGridFacade.togglePlaying(
								gridId,
								true,
								status,
							);
						return EMPTY as Observable<Action>;
					}),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.memory-phase.error.update-grid.title',
								),
								text: this.transService.instant(
									'network.memory-phase.error.update-grid.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	public togglePlaying$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MemoryGridActions.togglePlaying),
			concatLatestFrom(({ gridId }: MemoryGridTogglePlayingAction) => [
				this.store.select(GamesSelectors.id),
				this.store.select(MemoryGridSelectors.time(gridId)),
			]),
			exhaustMap(
				([action, gameId, time]: [
					MemoryGridTogglePlayingAction,
					string,
					number,
				]) => {
					const { gridId, playing } = action;
					const pathVariables: MemoryGridUpdatePathVariables = {
						gameId,
						memoryGridId: gridId,
					};

					const body: ToggleMemoryGridRequest = {
						_type: UpdateMemoryGridRequestType.Toggle,
						playing,
						time,
					};

					return this.memoryGridService
						.update(pathVariables, body)
						.pipe(
							concatMap(() => EMPTY as Observable<Action>),
							catchError(() =>
								of(
									ToastActions.sendMessage({
										title: this.transService.instant(
											'network.memory-phase.error.update-grid.title',
										),
										text: this.transService.instant(
											'network.memory-phase.error.update-grid.text',
										),
										mode: 'error',
									}),
								),
							),
						);
				},
			),
		);
	});

	public fix$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MemoryGridActions.fix),
			concatLatestFrom(() => this.store.select(GamesSelectors.id)),
			exhaustMap(([action, gameId]: [MemoryGridFixAction, string]) => {
				const { gridId, wordsNumberHitted } = action;
				const pathVariables: MemoryGridUpdatePathVariables = {
					gameId,
					memoryGridId: gridId,
				};

				const body: FixMemoryGridRequest = {
					_type: UpdateMemoryGridRequestType.Fix,
					wordsNumberHitted,
				};

				return this.memoryGridService.update(pathVariables, body).pipe(
					concatMap(() => EMPTY as Observable<Action>),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.memory-phase.error.update-grid.title',
								),
								text: this.transService.instant(
									'network.memory-phase.error.update-grid.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private memoryGridFacade: MemoryGridFacade,
		private memoryGridService: MemoryGridService,
		private transService: TranslateService,
	) {}
}
