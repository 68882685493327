import { Hint } from '@api/models/hint.entity';
import { createAction, props } from '@ngrx/store';

export interface HintsLoadSuccessAction {
	hints: Hint[];
}

export class HintsActions {
	public static load = createAction('[Hints] Load');

	public static loadSuccess = createAction(
		'[Hints] Load Success',
		props<HintsLoadSuccessAction>(),
	);
}
