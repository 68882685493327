import { mergeMap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ApiService } from '@api/services/api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AuthActions } from '../auth/auth.actions';
import { DeviceActions } from '../device/device.actions';
import { HintsActions } from '../hints/hints.actions';
import { UsersActions } from '../users/users.actions';
import { AppActions } from './app.actions';
import { AppFacade } from './app.facade';

@Injectable()
export class AppEffects {
	public initialize$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AppActions.initialize),
			switchMap(() => this.apiService.get()),
			mergeMap((beVersion: string) => {
				this.appFacade.setBeVersion(beVersion);

				return [
					AuthActions.getKey(),
					AuthActions.refreshAllTokens(),
					UsersActions.join(),
					DeviceActions.setInitialVolume(),
					HintsActions.load(),
				];
			}),
		);
	});

	constructor(
		private actions$: Actions,
		private apiService: ApiService,
		private appFacade: AppFacade,
	) {}
}
