import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
	ImagesService,
	ImagesToonifyFormData,
} from '@api/services/images.service';

@Injectable({
	providedIn: 'root',
})
export class ImagesCustomService extends ImagesService {
	public toonifyCustom(body: ImagesToonifyFormData): Observable<Blob> {
		return this.toonify(body).pipe(
			switchMap((imageUrl: string) => from(fetch(imageUrl))),
			switchMap((response: Response) => from(response.blob())),
		);
	}
}
