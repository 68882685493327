import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AnswerSongClueRequest } from '@api/models/answer-song-clue.request';
import { UpdateSongClueRequestType } from '@api/models/enums';
import { ObtainTurnSongClueRequest } from '@api/models/obtain-turn-song-clue.request';
import { Player } from '@api/models/player.entity';
import { SongClue } from '@api/models/song-clue.entity';
import { SongRound } from '@api/models/song-round.entity';
import { UpdateStatusSongClueRequest } from '@api/models/update-status-song-clue.request';
import {
	SongClueService,
	SongClueUpdatePathVariables,
} from '@api/services/song-clue.service';
import { ToastActions } from '@bussiness/store/components/toast/toast.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { GamesSelectors } from '../../games.selectors';
import { PlayersFacade } from '../../players/players.facade';
import { SongRoundSelectors } from '../song-round/song-round.selectors';
import {
	SongClueActions,
	SongClueTryToAnswerAction,
	SongClueUpdateStatusAction,
	SongClueValidateAnswerAction,
} from './song-clue.actions';
import { SongClueFacade } from './song-clue.facade';
import { IndexedSongClue, SongClueSelectors } from './song-clue.selectors';

@Injectable()
export class SongClueEffects {
	public updateStatus$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SongClueActions.updateStatus),
			concatLatestFrom(({ songRoundId }: SongClueUpdateStatusAction) => [
				this.store.select(GamesSelectors.id),
				this.store.select(SongClueSelectors.current(songRoundId)),
			]),
			exhaustMap(
				([action, gameId, clueWithIndex]: [
					SongClueUpdateStatusAction,
					string,
					IndexedSongClue,
				]) => {
					const { songRoundId, status } = action;
					const { _id: clueId } = clueWithIndex;

					const pathVariables: SongClueUpdatePathVariables = {
						gameId,
						songRoundId,
						id: clueId,
					};

					const body: UpdateStatusSongClueRequest = {
						_type: UpdateSongClueRequestType.StatusChange,
						status,
					};

					return this.songClueService
						.update(pathVariables, body)
						.pipe(
							map((updatedClue) =>
								SongClueActions.updateSuccess({
									songRoundId,
									clue: updatedClue as SongClue,
								}),
							),
							catchError(() =>
								of(
									ToastActions.sendMessage({
										title: this.transService.instant(
											'network.song-phase.error.update-clue.title',
										),
										text: this.transService.instant(
											'network.song-phase.error.update-clue.text',
										),
										mode: 'error',
									}),
								),
							),
						);
				},
			),
		);
	});

	public tryToAnswer$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SongClueActions.tryToAnswer),
			concatLatestFrom(() => [
				this.store.select(GamesSelectors.id),
				this.store.select(SongRoundSelectors.all),
				this.songClueFacade.currentFromPlayer$,
				this.playersFacade.current$,
			]),
			exhaustMap(
				([action, gameId, rounds, clue, player]: [
					SongClueTryToAnswerAction,
					string,
					SongRound[],
					SongClue,
					Player,
				]) => {
					const { _id: clueId } = clue;
					const round = rounds.find(({ clues }: SongRound) =>
						clues.find(({ _id }: SongClue) => _id === clueId),
					);

					const { _id: playerId } = player;
					const { reactionMiliseconds } = action;

					const pathVariables: SongClueUpdatePathVariables = {
						gameId,
						songRoundId: round._id,
						id: clueId,
					};

					const body: ObtainTurnSongClueRequest = {
						_type: UpdateSongClueRequestType.ObtainTurn,
						playerId,
						reactionMiliseconds,
					};

					return this.songClueService
						.update(pathVariables, body)
						.pipe(
							concatMap(() => EMPTY as Observable<Action>),
							catchError(() =>
								of(
									ToastActions.sendMessage({
										title: this.transService.instant(
											'network.song-phase.error.obtain-turn.title',
										),
										text: this.transService.instant(
											'network.song-phase.error.obtain-turn.text',
										),
										mode: 'error',
									}),
								),
							),
						);
				},
			),
		);
	});

	public validateAnswer$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SongClueActions.validateAnswer),
			concatLatestFrom(
				({ songRoundId }: SongClueValidateAnswerAction) => [
					this.store.select(GamesSelectors.id),
					this.store.select(SongClueSelectors.current(songRoundId)),
				],
			),
			exhaustMap(
				([action, gameId, clueWithIndex]: [
					SongClueValidateAnswerAction,
					string,
					IndexedSongClue,
				]) => {
					const { songRoundId, answerType } = action;
					const { _id: clueId } = clueWithIndex;

					const pathVariables: SongClueUpdatePathVariables = {
						gameId,
						songRoundId,
						id: clueId,
					};

					const body: AnswerSongClueRequest = {
						_type: UpdateSongClueRequestType.Answer,
						answerType,
					};

					return this.songClueService
						.update(pathVariables, body)
						.pipe(
							map((updatedClue) =>
								SongClueActions.updateSuccess({
									songRoundId,
									clue: updatedClue as SongClue,
								}),
							),
							catchError(() =>
								of(
									ToastActions.sendMessage({
										title: this.transService.instant(
											'network.song-phase.error.update-answer.title',
										),
										text: this.transService.instant(
											'network.song-phase.error.update-answer.text',
										),
										mode: 'error',
									}),
								),
							),
						);
				},
			),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private songClueService: SongClueService,
		private songClueFacade: SongClueFacade,
		private playersFacade: PlayersFacade,
		private transService: TranslateService,
	) {}
}
