import { EMPTY, Observable, of } from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	exhaustMap,
	map,
	switchMap,
	tap,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CreateGamePhaseRequest } from '@api/models/create-game-phase.request';
import { CreateGameRequest } from '@api/models/create-game.request';
import { GameLoginType, GameType, PhaseKind } from '@api/models/enums';
import { GamePageResponse } from '@api/models/game-page.response';
import { GamePrivateLoginRequest } from '@api/models/game-private-login.request';
import { Game } from '@api/models/game.entity';
import {
	GamesService,
	GamesUpdatePathVariables,
} from '@api/services/games.service';
import { AuthCustomService } from '@bussiness/custom-apis/auth.custom-service';
import { TokenGame } from '@bussiness/custom-models/auth.custom-models';
import { Phase } from '@bussiness/custom-models/game.custom-models';
import {
	PlcGamePathsGamePickerParams,
	PlcGamePathsPhaseParams,
	PlcGameRoute,
} from '@bussiness/resources/common/routes.constants';
import { ToastActions } from '@bussiness/store/components/toast/toast.actions';
import { GamesFacade } from '@bussiness/store/features/games/games.facade';
import { UsersSelectors } from '@bussiness/store/features/users/users.selectors';
import { StringUtils } from '@bussiness/utils/string.utils';
import { GameNavigationType } from '@core/models/games/events/games.events';
import { GamesSockets } from '@core/services/sockets/games.sockets';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AuthActions } from '../auth/auth.actions';
import {
	GameAddPhaseAction,
	GameAddPhaseSuccessAction,
	GamesActions,
	GamesCreateAction,
	GamesGetCurrentAction,
	GamesJoinAction,
	GamesLoadAction,
} from './games.actions';
import { GamesSelectors } from './games.selectors';

@Injectable()
export class GamesEffects {
	public $create: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(GamesActions.create),
			concatLatestFrom(() => this.store.select(UsersSelectors.currentId)),
			exhaustMap(([action, owner]: [GamesCreateAction, string]) => {
				let newGame: Game;

				const { playersAmount, name, password } = action;
				const body = new CreateGameRequest({
					playersAmount,
					name,
					password,
					owner,
				});

				return this.gamesService.create(body).pipe(
					switchMap((game: Game) => {
						newGame = game;

						const { gameType, name, password } = action;
						const _type =
							gameType === GameType.Private
								? GameLoginType.Private
								: GameLoginType.Public;

						const body = new GamePrivateLoginRequest({
							_type,
							name,
							password,
						});
						return this.authCustomService.gameLogin(body);
					}),
					concatMap((gameToken: TokenGame) => {
						const { iat, exp } = gameToken;

						return [
							AuthActions.loginGameSuccess({ iat, exp }),
							GamesActions.createSuccess({
								game: newGame,
							}),
						];
					}),
					delay(0),
					tap(() => {
						const { _id: gameId } = newGame;
						const params: PlcGamePathsGamePickerParams = {
							gameId,
						};
						const path =
							StringUtils.populatePath<PlcGamePathsGamePickerParams>(
								PlcGameRoute.RolePicker,
								params,
							);
						this.router.navigateByUrl(path);
					}),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.games.error.create.title',
								),
								text: this.transService.instant(
									'network.games.error.create.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	public load$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(GamesActions.load),
			exhaustMap(({ query }: GamesLoadAction) => {
				if (this._lastGamePageResponse) {
					const { page: lastPageQueried, items } =
						this._lastGamePageResponse;

					if (items.length === 0 && query.page > lastPageQueried)
						return EMPTY as Observable<Action>;
				}

				return this.gamesService.findPage(query).pipe(
					map((page: GamePageResponse) => {
						this._lastGamePageResponse = page;

						if (page.page === 1)
							return GamesActions.loadSuccess({
								games: page.items,
							});
						else
							return GamesActions.loadNextSuccess({
								games: page.items,
							});
					}),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.games.error.search.title',
								),
								text: this.transService.instant(
									'network.games.error.search.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	public getCurrent$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(GamesActions.getCurrent),
			exhaustMap(({ _id, goToRolePicker }: GamesGetCurrentAction) => {
				return this.gamesService.findOne({ id: _id }).pipe(
					map((game: Game) =>
						GamesActions.getCurrentSuccess({
							game,
						}),
					),
					delay(0),
					tap(() => {
						if (goToRolePicker) {
							const params: PlcGamePathsGamePickerParams = {
								gameId: _id,
							};
							const path =
								StringUtils.populatePath<PlcGamePathsGamePickerParams>(
									PlcGameRoute.RolePicker,
									params,
								);
							this.router.navigateByUrl(path);
						}
					}),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.games.error.get-current.title',
								),
								text: this.transService.instant(
									'network.games.error.get-current.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	public join$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(GamesActions.join),
			concatMap(({ gameId }: GamesJoinAction) => {
				this.gamesSockets.join(gameId);
				return EMPTY as Observable<Action>;
			}),
		);
	});

	public addPhase$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(GamesActions.addPhase),
			concatLatestFrom(() => this.store.select(GamesSelectors.id)),
			exhaustMap(([{ kind }, gameId]: [GameAddPhaseAction, string]) => {
				const pathVariables: GamesUpdatePathVariables = {
					id: gameId,
				};

				const body: CreateGamePhaseRequest = {
					phaseKind: kind,
				};

				return this.gamesService.update(pathVariables, body).pipe(
					concatMap(() => EMPTY as Observable<Action>),
					catchError((err) => {
						console.error(err);
						return of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.games.error.add-phase.title',
								),
								text: this.transService.instant(
									'network.games.error.add-phase.text',
								),
								mode: 'error',
							}),
						);
					}),
				);
			}),
		);
	});

	public addPhaseSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(GamesActions.addPhaseSuccess),
			concatLatestFrom(() => this.store.select(GamesSelectors.id)),
			concatMap(
				([{ phase }, gameId]: [GameAddPhaseSuccessAction, string]) => {
					let navigationType: GameNavigationType;
					const { kind } = phase;

					if (kind === PhaseKind.Soups)
						navigationType = GameNavigationType.SoupsPhaseGame;
					else if (kind === PhaseKind.Memory)
						navigationType = GameNavigationType.MemoryPhaseGame;
					else if (kind === PhaseKind.Song)
						navigationType = GameNavigationType.SongPhaseGame;
					else if (kind === PhaseKind.Bagels)
						navigationType = GameNavigationType.BagelsPhaseGame;

					this.gamesFacade.triggerNavigation(gameId, navigationType);

					const params: PlcGamePathsPhaseParams = {
						gameId,
						kind,
						page: 'dashboard',
					};
					const path =
						StringUtils.populatePath<PlcGamePathsPhaseParams>(
							PlcGameRoute.Phase,
							params,
						);
					this.router.navigateByUrl(path);

					return EMPTY as Observable<Action>;
				},
			),
		);
	});

	public moveToNextPhase$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(GamesActions.moveToNextPhase),
			concatLatestFrom(() => [
				this.store.select(GamesSelectors.phases),
				this.store.select(GamesSelectors.id),
			]),
			concatMap(([, phases, gameId]: [never, Phase[], string]) => {
				const nextPhase = phases.find(({ over }) => !over);
				let navigationType: GameNavigationType;

				if (nextPhase.kind === PhaseKind.Soups)
					navigationType = GameNavigationType.SoupsPhasePreview;
				else if (nextPhase.kind === PhaseKind.Memory)
					navigationType = GameNavigationType.MemoryPhasePreview;
				else if (nextPhase.kind === PhaseKind.Song)
					navigationType = GameNavigationType.SongPhasePreview;
				else if (nextPhase.kind === PhaseKind.Bagels)
					navigationType = GameNavigationType.BagelsPhasePreview;

				this.gamesFacade.triggerNavigation(gameId, navigationType);

				const params: PlcGamePathsPhaseParams = {
					gameId,
					kind: nextPhase.kind,
					page: 'preview',
				};
				const path = StringUtils.populatePath<PlcGamePathsPhaseParams>(
					PlcGameRoute.Phase,
					params,
				);
				this.router.navigateByUrl(path);

				return EMPTY as Observable<Action>;
			}),
		);
	});

	private _lastGamePageResponse: GamePageResponse;

	constructor(
		private actions$: Actions,
		private gamesService: GamesService,
		private store: Store,
		private gamesSockets: GamesSockets,
		private router: Router,
		private gamesFacade: GamesFacade,
		private authCustomService: AuthCustomService,
		private transService: TranslateService,
	) {}
}
