import { EMPTY, Observable } from 'rxjs';
import { concatMap, exhaustMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { BagelQuestion } from '@api/models/bagel-question.entity';
import { UpdateBagelQuestionV3Request } from '@api/models/update-bagel-question-v3.request';
import {
	BagelQuestionService,
	BagelQuestionUpdateV3PathVariables,
} from '@api/services/bagel-question.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { GamesSelectors } from '../../games.selectors';
import { BagelsPhaseSelectors } from '../bagels-phase.selectors';
import {
	BagelQuestionActions,
	BagelQuestionUpdateAction,
} from './bagel-question.actions';

@Injectable()
export class BagelQuestionEffects {
	public update$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(BagelQuestionActions.update),
			concatLatestFrom(({ bagelId }: BagelQuestionUpdateAction) => [
				this.store.select(GamesSelectors.id),
				this.store.select(BagelsPhaseSelectors.inGame(bagelId)),
				this.store.select(BagelsPhaseSelectors.time(bagelId)),
			]),
			exhaustMap(
				([action, gameId, question, time]: [
					BagelQuestionUpdateAction,
					string,
					BagelQuestion,
					number,
				]) => {
					const { bagelId, status } = action;
					const { _id } = question;

					const pathVariables: BagelQuestionUpdateV3PathVariables = {
						gameId,
						bagelId,
						id: _id,
					};

					const body: UpdateBagelQuestionV3Request = {
						status,
						time,
					};

					return this.bagelQuestionService
						.updateV3(pathVariables, body)
						.pipe(concatMap(() => EMPTY as Observable<Action>));
				},
			),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private bagelQuestionService: BagelQuestionService,
	) {}
}
