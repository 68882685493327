import { createFeatureSelector, createSelector } from '@ngrx/store';

import { hintsFeatureKey, HintsState } from './hints.reducer';

export const selectHintsState =
	createFeatureSelector<HintsState>(hintsFeatureKey);

export class HintsSelectors {
	public static all = createSelector(
		selectHintsState,
		(state: HintsState) => state.list,
	);
}
