import {
	animate,
	AnimationGroupMetadata,
	group,
	query,
	style,
	transition,
	trigger,
} from '@angular/animations';

const getMetadata = (
	transition: 'reset' | 'rtl' | 'ltr',
): AnimationGroupMetadata[] => {
	const enterTranslate =
		transition === 'reset' || transition === 'ltr' ? -100 : 100;
	const leaveTranslate =
		transition === 'reset' || transition === 'ltr' ? 100 : -100;

	return [
		group([
			query(
				':enter',
				style({
					// position: 'fixed',
					// width: '100%',
					// margin: 'auto',
					transform: `translateX(${enterTranslate}vw)`,
				}),
				{ optional: true },
			),
			query(
				':leave',
				animate(
					'500ms ease',
					style({
						transform: `translateX(${leaveTranslate}vw)`,
					}),
				),
				{ optional: true },
			),
			query(
				':enter',
				animate('500ms ease', style({ transform: 'translateX(0)' })),
				{ optional: true },
			),
		]),
	];
};

export const navigationAnimation = trigger('plcNavigationAnimation', [
	transition(':increment', getMetadata('rtl')),
	transition(':decrement', getMetadata('ltr')),
	transition('* => 0', getMetadata('reset')),
]);
