import { storeFreeze } from 'ngrx-store-freeze';

import {
	wizardReducer,
	WizardState,
} from '@bussiness/store/components/wizard/wizard.reducer';
import {
	authReducer,
	AuthState,
} from '@bussiness/store/features/auth/auth.reducer';
import {
	gamesReducer,
	GamesState,
} from '@bussiness/store/features/games/games.reducer';
import {
	usersReducer,
	UsersState,
} from '@bussiness/store/features/users/users.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { loaderReducer, LoaderState } from './components/loader/loader.reducer';
import { devicesReducer, DeviceState } from './features/device/device.reducer';
import { hintsReducer, HintsState } from './features/hints/hints.reducer';
import { plcStorageSyncReducer } from './storage-sync.reducer';

export interface AppState {
	users: UsersState;
	auth: AuthState;
	wizard: WizardState;
	games: GamesState;
	router: RouterReducerState;
	device: DeviceState;
	hints: HintsState;
	loader: LoaderState;
}

export const reducers: ActionReducerMap<AppState> = {
	users: usersReducer,
	auth: authReducer,
	wizard: wizardReducer,
	games: gamesReducer,
	router: routerReducer,
	device: devicesReducer,
	hints: hintsReducer,
	loader: loaderReducer,
};

export function logger(
	reducer: ActionReducer<AppState>,
): ActionReducer<AppState> {
	return function (state: AppState, action: any): AppState {
		return reducer(state, action);
	};
}

export const prodMetaReducers: MetaReducer<AppState>[] = [
	plcStorageSyncReducer,
];
export const devMetaReducers: MetaReducer<AppState>[] = [
	logger,
	storeFreeze,
	plcStorageSyncReducer,
];
