import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderActions } from '@bussiness/store/components/loader/loader.actions';
import { Store } from '@ngrx/store';

interface LoadReasonConfig {
	regex: RegExp;
	key: string;
	message: string;
}

const END_OF_URL = '\\b(?![^?])';
const PATH_VARIABLE = '([^/]+)';

@Injectable()
export class LoadReasonInterceptor implements HttpInterceptor {
	private readonly _config: LoadReasonConfig[] = [
		{
			regex: new RegExp(`\\/v2\\/private\\/games${END_OF_URL}`),
			key: 'loadGames',
			message: 'Buscando partidas',
		},
		{
			regex: new RegExp(`\\/v2\\/private\\/games\\/${PATH_VARIABLE}`),
			key: 'loadGame',
			message: 'Obteniendo los detalles de la partida',
		},
		{
			regex: new RegExp(
				`\\/v3\\/public\\/auth\\/game-login${END_OF_URL}`,
			),
			key: 'loginGame',
			message: 'Identificandose en la partida',
		},
		{
			regex: new RegExp(`\\/v2\\/public\\/hints${END_OF_URL}`),
			key: 'loadHints',
			message: 'Obteniendo pistas de la base de datos',
		},
		{
			regex: new RegExp(
				`\\/v3\\/public\\/auth\\/user-login${END_OF_URL}`,
			),
			key: 'loginUser',
			message: 'Identificando usuario',
		},
		{
			regex: new RegExp(`\\/v2\\/private\\/users\\/${PATH_VARIABLE}`),
			key: 'loadUser',
			message: 'Obteniendo datos del usuario',
		},
		{
			regex: new RegExp(
				`\\/v2\\/public\\/auth\\/token-refresh${END_OF_URL}`,
			),
			key: 'refreshSessions',
			message: 'Renovando sesiones almacenadas',
		},
	];

	constructor(private store: Store) {
		const duplicatedKeys = this._config
			.map(({ key }) => key)
			.filter((key, index, array) => array.indexOf(key) !== index);

		if (duplicatedKeys.length)
			console.error(
				`Following keys:\n${duplicatedKeys
					.map((key) => `\n - ${key}`)
					.join('')}
				\nare duplicated. This may cause unexpected behavior.`,
			);
	}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		const { url } = request;
		const config = this._config.find(({ regex: pattern }) =>
			url.match(pattern),
		);

		if (config) {
			const { key, message } = config;
			this.store.dispatch(
				LoaderActions.addReason({ value: message, key }),
			);
		}

		return next.handle(request).pipe(
			finalize(() => {
				if (config) {
					const { key } = config;
					this.store.dispatch(LoaderActions.removeReason({ key }));
				}
			}),
		);
	}
}
