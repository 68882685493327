import { EMPTY, Observable } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Song } from '@api/models/song.entity';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { DeviceFacade } from '../../device/device.facade';
import { SongPhaseActions } from './song-phase.actions';
import { SongPhaseSelectors } from './song-phase.selectors';

@Injectable()
export class SongPhaseEffects {
	public preloadSongs$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SongPhaseActions.preloadSongs),
			concatLatestFrom(() =>
				this.store.select(SongPhaseSelectors.allSongs),
			),
			exhaustMap(([, songs]: [never, Song[]]) => {
				const audios = songs.map(({ _id, trackUrl }: Song) => ({
					id: _id,
					src: trackUrl,
				}));
				this.deviceFacade.preloadAudios(...audios);
				return EMPTY as Observable<Action>;
			}),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private deviceFacade: DeviceFacade,
	) {}
}
