import { EMPTY, Observable, of } from 'rxjs';
import {
	catchError,
	exhaustMap,
	map,
	mergeMap,
	switchMap,
	tap,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import {
	GameLoginType,
	UserLoginType,
	UserResetPswStep,
	UserSignInStep,
} from '@api/models/enums';
import { GamePrivateLoginRequest } from '@api/models/game-private-login.request';
import { UserAnonymousLoginRequest } from '@api/models/user-anonymous-login.request';
import { UserConfirmAccountRequest } from '@api/models/user-confirm-account.request';
import { UserNormalLoginRequest } from '@api/models/user-normal-login.request';
import { UserResetPswStepThreeRequest } from '@api/models/user-reset-psw-step-three.request';
import { UserResetPswStepTwoRequest } from '@api/models/user-reset-psw-step-two.request';
import { UserSignInRequest } from '@api/models/user-sign-in.request';
import { User } from '@api/models/user.entity';
import { UsersService } from '@api/services/users.service';
import { AuthCustomService } from '@bussiness/custom-apis/auth.custom-service';
import {
	TokenGame,
	TokenPlayer,
	TokenRefreshed,
	TokenUser,
} from '@bussiness/custom-models/auth.custom-models';
import {
	PlcPublicRoute,
	PlcResetPassFormStep,
} from '@bussiness/resources/common/routes.constants';
import { ToastActions } from '@bussiness/store/components/toast/toast.actions';
import { UsersActions } from '@bussiness/store/features/users/users.actions';
import { AuthUtil } from '@bussiness/utils/auth.util';
import { UsersSockets } from '@core/services/sockets/users.sockets';
import {
	LocalStorageEnum,
	LocalStorageService,
} from '@core/services/storage/local-storage.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { DeviceActions } from '../device/device.actions';
import { GamesActions } from '../games/games.actions';
import { GamesSelectors } from '../games/games.selectors';
import { AuthFacade } from './auth-facade';
import {
	AuthActions,
	AuthCheckTempPswAction,
	AuthConfirmUserAction,
	AuthFastLoginUserAction,
	AuthFastLoginUserSuccessAction,
	AuthLoginGameAction,
	AuthLoginPlayerAction,
	AuthRequestPswResetAction,
	AuthResetPswAction,
	AuthSendCredentialsAction,
	AuthSignInUserAction,
} from './auth.actions';

@Injectable()
export class AuthEffects {
	public getKey$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.getKey),
			exhaustMap(() =>
				this.authCustomService
					.getPublicKey()
					.pipe(mergeMap(() => EMPTY as Observable<Action>)),
			),
		);
	});

	public signInUser$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.signInUser),
			exhaustMap((action: AuthSignInUserAction) => {
				const { name, email, password } = action;
				const body = new UserSignInRequest({
					_step: UserSignInStep.Create,
					name,
					email,
					password,
				});

				return this.authCustomService.register(body).pipe(
					map(() => AuthActions.signInUserSuccess()),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.auth.error.sign-in.title',
								),
								text: this.transService.instant(
									'network.auth.error.sign-in.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	public confirmUser$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.confirmUser),
			exhaustMap(({ token }: AuthConfirmUserAction) => {
				const request: UserConfirmAccountRequest = {
					_step: UserSignInStep.Confirm,
					token,
				};
				return this.authCustomService.register(request).pipe(
					map((user: User) =>
						AuthActions.confirmUserSuccess({
							user,
						}),
					),
				);
			}),
		);
	});

	public loginUser$: Observable<Action | Action[]> = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.loginUser),
			exhaustMap(({ name, password }: AuthSendCredentialsAction) => {
				const body = new UserNormalLoginRequest({
					name,
					password,
					_type: UserLoginType.Normal,
				});

				return this.authCustomService.userLogin(body).pipe(
					switchMap((userToken: TokenUser) => {
						const { _id, iat, exp } = userToken;

						return this.usersService.findOne({ id: _id }).pipe(
							tap(({ _id }: User) => this.usersSockets.join(_id)),
							mergeMap((user: User) => [
								AuthActions.loginUserSuccess({
									iat,
									exp,
								}),
								UsersActions.setCurrent({
									user,
								}),
								DeviceActions.setVolume({
									volume: user.preferences.volume,
								}),
							]),
						);
					}),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.auth.error.log-in.title',
								),
								text: this.transService.instant(
									'network.auth.error.log-in.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	public fastLoginUser$: Observable<Action | Action[]> = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.fastLoginUser),
			exhaustMap(({ name, targetPath }: AuthFastLoginUserAction) => {
				const body = new UserSignInRequest({
					_step: UserSignInStep.Create,
					name,
					email: `${name}@mail.com`,
					password: null,
				});

				return this.authCustomService.register(body).pipe(
					mergeMap((user: User) => {
						const body: UserAnonymousLoginRequest = {
							_type: UserLoginType.Anonymous,
							_id: user._id,
						};
						return this.authCustomService.userLogin(body).pipe(
							mergeMap((userToken: TokenUser) => {
								const { iat, exp } = userToken;

								return [
									AuthActions.loginUserSuccess({
										iat,
										exp,
									}),
									UsersActions.setCurrent({
										user,
									}),
									AuthActions.fastLoginUserSuccess({
										targetPath,
									}),
								];
							}),
						);
					}),
					catchError(() =>
						of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.auth.error.fast-log-in.title',
								),
								text: this.transService.instant(
									'network.auth.error.fast-log-in.text',
								),
								mode: 'error',
							}),
						),
					),
				);
			}),
		);
	});

	public fastLoginUserSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.fastLoginUserSuccess),
			map(({ targetPath }: AuthFastLoginUserSuccessAction) => {
				this.router.navigateByUrl(targetPath);
				return ToastActions.sendMessage({
					title: this.transService.instant(
						'network.auth.success.fast-log-in.title',
					),
					text: this.transService.instant(
						'network.auth.success.fast-log-in.text',
					),
					mode: 'info',
				});
			}),
		);
	});

	public logoutUser$: Observable<Action | Action[]> = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.logoutUser),
			exhaustMap(() => {
				this.storage.remove(LocalStorageEnum.PlayerToken);
				this.storage.remove(LocalStorageEnum.GameToken);
				this.storage.remove(LocalStorageEnum.UserToken);

				this.router.navigateByUrl(PlcPublicRoute.Home);

				return [
					AuthActions.logoutUserSuccess(),
					UsersActions.deleteCurrent(),
					ToastActions.sendMessage({
						title: this.transService.instant(
							'network.auth.success.log-out.title',
						),
						text: this.transService.instant(
							'network.auth.success.log-out.text',
						),
						mode: 'info',
					}),
				];
			}),
		);
	});

	public requestPasswordReset$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.requestPasswordReset),
			exhaustMap(({ email }: AuthRequestPswResetAction) =>
				this.authCustomService
					.userResetPassword({ _step: UserResetPswStep.One, email })
					.pipe(
						mergeMap(() => {
							const queryParams: Params = {
								step: 'code' as PlcResetPassFormStep,
							};
							this.router.navigate(
								[PlcPublicRoute.ResetPassword],
								{ queryParams },
							);
							return EMPTY as Observable<Action>;
						}),
						catchError(() => {
							return of(
								ToastActions.sendMessage({
									title: this.transService.instant(
										'network.auth.error.request-reset-password.title',
									),
									text: this.transService.instant(
										'network.auth.error.request-reset-password.text',
									),
									mode: 'error',
								}),
							);
						}),
					),
			),
		);
	});

	public checkTempPassword$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.checkTempPassword),
			exhaustMap(({ email, code }: AuthCheckTempPswAction) => {
				const body = new UserResetPswStepTwoRequest({
					_step: UserResetPswStep.Two,
					email,
					tempPass: code,
				});

				return this.authCustomService.userResetPassword(body).pipe(
					mergeMap(() => {
						const queryParams: Params = {
							step: 'new-pass' as PlcResetPassFormStep,
						};
						this.router.navigate([PlcPublicRoute.ResetPassword], {
							queryParams,
						});
						return EMPTY as Observable<Action>;
					}),
					catchError(() => {
						return of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.auth.error.check-temporal-password.title',
								),
								text: this.transService.instant(
									'network.auth.error.check-temporal-password.text',
								),
								mode: 'error',
							}),
						);
					}),
				);
			}),
		);
	});

	public resetPassword$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.resetPassword),
			exhaustMap(({ email, password }: AuthResetPswAction) => {
				const body = new UserResetPswStepThreeRequest({
					_step: UserResetPswStep.Three,
					email,
					password,
				});

				return this.authCustomService.userResetPassword(body).pipe(
					mergeMap(() => {
						const queryParams: Params = {
							step: 'confirm' as PlcResetPassFormStep,
						};
						this.router.navigate([PlcPublicRoute.ResetPassword], {
							queryParams,
						});
						return EMPTY as Observable<Action>;
					}),
					catchError(() => {
						return of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.auth.error.reset-password.title',
								),
								text: this.transService.instant(
									'network.auth.error.reset-password.text',
								),
								mode: 'error',
							}),
						);
					}),
				);
			}),
		);
	});

	public loginGame$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.loginGame),
			exhaustMap(({ _id, name, password }: AuthLoginGameAction) => {
				const body = new GamePrivateLoginRequest({
					_type: password
						? GameLoginType.Private
						: GameLoginType.Public,
					name,
					password,
				});
				return this.authCustomService.gameLogin(body).pipe(
					mergeMap((gameToken: TokenGame) => {
						const { iat, exp } = gameToken;
						return [
							AuthActions.loginGameSuccess({ iat, exp }),
							GamesActions.getCurrent({
								_id,
								goToRolePicker: true,
							}),
						];
					}),
					catchError(() => {
						return of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.auth.error.log-in-game.title',
								),
								text: this.transService.instant(
									'network.auth.error.log-in-game.text',
								),
								mode: 'error',
							}),
						);
					}),
				);
			}),
		);
	});

	public loginPlayer$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.loginPlayer),
			concatLatestFrom(() => this.store.select(GamesSelectors.id)),
			exhaustMap(
				([{ player }, gameId]: [AuthLoginPlayerAction, string]) => {
					const { _id: playerId } = player;

					return this.authCustomService
						.playerLoginCustom({ gameId, playerId })
						.pipe(
							map((playerToken: TokenPlayer) => {
								const { iat, exp } = playerToken;
								return AuthActions.loginPlayerSuccess({
									iat,
									exp,
								});
							}),
							catchError(() => {
								return of(
									ToastActions.sendMessage({
										title: this.transService.instant(
											'network.auth.error.log-in-player.title',
										),
										text: this.transService.instant(
											'network.auth.error.log-in-player.text',
										),
										mode: 'error',
									}),
								);
							}),
						);
				},
			),
		);
	});

	public logoutPlayer$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.logoutPlayer),
			concatLatestFrom(() => this.store.select(GamesSelectors.id)),
			exhaustMap(
				([{ player }, gameId]: [AuthLoginPlayerAction, string]) => {
					const { _id: playerId } = player;

					return this.authCustomService
						.playerLogoutCustom({ gameId, playerId })
						.pipe(
							map(() => {
								return AuthActions.loginPlayerSuccess({
									iat: null,
									exp: null,
								});
							}),
							catchError(() => {
								return of(
									ToastActions.sendMessage({
										title: this.transService.instant(
											'network.auth.error.log-out-player.title',
										),
										text: this.transService.instant(
											'network.auth.error.log-out-player.text',
										),
										mode: 'error',
									}),
								);
							}),
						);
				},
			),
		);
	});

	public refreshAllTokens$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.refreshAllTokens),
			exhaustMap(() => {
				const tokensExpired = this.authUtil.getExpiredTokens();
				if (tokensExpired.length === 0)
					return EMPTY as Observable<Action>;

				return this.authCustomService.refreshAllTokens().pipe(
					mergeMap((tokens: TokenRefreshed[]) => {
						this.authFacade.refreshTokensSuccess(tokens);
						return EMPTY as Observable<Action>;
					}),
					catchError(() => {
						return of(
							ToastActions.sendMessage({
								title: this.transService.instant(
									'network.auth.error.token-refresh.title',
								),
								text: this.transService.instant(
									'network.auth.error.token-refresh.text',
								),
								mode: 'warn',
							}),
						);
					}),
				);
			}),
		);
	});

	constructor(
		private store: Store,
		private actions$: Actions,
		private authFacade: AuthFacade,
		private authCustomService: AuthCustomService,
		private authUtil: AuthUtil,
		private usersService: UsersService,
		private usersSockets: UsersSockets,
		private storage: LocalStorageService,
		private router: Router,
		private transService: TranslateService,
	) {}
}
