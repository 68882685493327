import { SocketIoModule } from 'ngx-socket-io';

import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from '@api/api.module';
import {
	BussinessModule,
	httpLoaderFactory,
	plcAppInitializerFactory,
} from '@bussiness/bussiness.module';
import { devMetaReducers, prodMetaReducers, reducers } from '@bussiness/store';
import { WizardEffects } from '@bussiness/store/components/wizard/wizard.effects';
import { AppEffects } from '@bussiness/store/features/app/app.effects';
import { AuthEffects } from '@bussiness/store/features/auth/auth.effects';
import { DeviceEffects } from '@bussiness/store/features/device/device.effects';
import { AlphabetSoupAnswerEffects } from '@bussiness/store/features/games/alphabet-soup-phase/alphabet-soup-answer/alphabet-soup-answer.effects';
import { AlphabetSoupRoundEffects } from '@bussiness/store/features/games/alphabet-soup-phase/alphabet-soup-round/alphabet-soup-round.effects';
import { AlphabetSoupEffects } from '@bussiness/store/features/games/alphabet-soup-phase/alphabet-soup/alphabet-soup.effects';
import { BagelQuestionEffects } from '@bussiness/store/features/games/bagels-phase/bagel-question/bagel-question.effects';
import { BagelsPhaseEffects } from '@bussiness/store/features/games/bagels-phase/bagels-phase.effects';
import { GamesEffects } from '@bussiness/store/features/games/games.effects';
import { MemoryCellEffects } from '@bussiness/store/features/games/memory-phase/memory-cell/memory-cell.effects';
import { MemoryGridEffects } from '@bussiness/store/features/games/memory-phase/memory-grid/memory-grid.effects';
import { MemoryPhaseEffects } from '@bussiness/store/features/games/memory-phase/memory-phase.effects';
import { PlayersEffects } from '@bussiness/store/features/games/players/players.effects';
import { SongClueEffects } from '@bussiness/store/features/games/song-phase/song-clue/song-clue.effects';
import { SongPhaseEffects } from '@bussiness/store/features/games/song-phase/song-phase.effects';
import { HintsEffects } from '@bussiness/store/features/hints/hints.effects';
import { UsersEffects } from '@bussiness/store/features/users/users.effects';
import { PlcLayoutModule } from '@components/modules/layout/layout.module';
import { CoreModule } from '@core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';

import { Environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebSharedModule } from './shared/web-shared.module';

const STORE_MAX_AGE = 40;

const ngrxImports = [
	StoreModule.forRoot(reducers, {
		metaReducers: Environment.production
			? prodMetaReducers
			: devMetaReducers,
	}),
	EffectsModule.forRoot([
		AppEffects,
		AuthEffects,
		UsersEffects,
		GamesEffects,
		PlayersEffects,
		BagelsPhaseEffects,
		BagelQuestionEffects,
		MemoryPhaseEffects,
		MemoryGridEffects,
		MemoryCellEffects,
		SongPhaseEffects,
		SongClueEffects,
		DeviceEffects,
		HintsEffects,
		AlphabetSoupRoundEffects,
		AlphabetSoupEffects,
		AlphabetSoupAnswerEffects,
		WizardEffects,
	]),
	StoreDevtoolsModule.instrument({
		name: 'Pastalacabra',
		logOnly: Environment.production,
		maxAge: STORE_MAX_AGE,
		connectInZone: true,
	}),
	StoreRouterConnectingModule.forRoot(),
];

if (Environment.name !== 'local')
	Sentry.init({
		dsn: Environment.sentryDns,
		replaysSessionSampleRate: Environment.sentryReplaysSessionSampleRate,
		replaysOnErrorSampleRate: 1.0,
		integrations: [
			new Sentry.Replay({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
	});

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		PlcLayoutModule,
		SocketIoModule.forRoot({
			url: Environment.wsUrl,
		}),
		CoreModule.forRoot({
			newUrl: Environment.oldApiUrl,
		}),
		ApiModule.forRoot({
			apiUrl: Environment.apiUrl,
		}),
		BussinessModule,
		...ngrxImports,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		WebSharedModule,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: plcAppInitializerFactory,
			deps: [],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
