import 'reflect-metadata';

const encryptMetadataKey = Symbol('Encrypt');

type Metadata = {
	(target: () => void): void;
	(target: unknown, propertyKey: string | symbol): void;
};

export function Encrypt(): Metadata {
	return Reflect.metadata(encryptMetadataKey, 'encrypt');
}

export function getEncryptMetadata(value: object, key: string): string {
	return Reflect.getMetadata(encryptMetadataKey, value, key);
}
