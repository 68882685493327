import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateIfUser } from '@bussiness/guards/user.guard';

const routes: Routes = [
	{
		path: 'private',
		loadChildren: () =>
			import('./private/private.module').then((m) => m.PrivateModule),
		canActivateChild: [canActivateIfUser],
	},
	{
		path: 'public',
		loadChildren: () =>
			import('./public/public.module').then((m) => m.PublicModule),
	},
	{ path: '**', redirectTo: 'public', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
