import { Observable } from 'rxjs';

import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	LocalStorageEnum,
	LocalStorageService,
} from '@core/services/storage/local-storage.service';

@Injectable()
export class GameTokenInterceptor implements HttpInterceptor {
	constructor(private storage: LocalStorageService) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		const gameToken = this.storage.get(
			LocalStorageEnum.GameToken,
		) as string;
		if (!gameToken) return next.handle(request);

		const headersWithToken = request.headers.append(
			LocalStorageEnum.GameToken,
			gameToken,
		);
		const reqWithToken = request.clone({ headers: headersWithToken });

		return next.handle(reqWithToken);
	}
}
