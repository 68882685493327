import { EMPTY, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Lang, Theme } from '@api/models/enums';
import { Hint } from '@api/models/hint.entity';
import { PreferencesConstants } from '@api/models/preferences.constants';
import { Preferences } from '@api/models/preferences.entity';
import { User } from '@api/models/user.entity';
import {
	PlcPrivateRoute,
	PlcPublicRoute,
} from '@bussiness/resources/common/routes.constants';
import {
	LOGGED_TOOLBAR_ENTRIES,
	NOT_LOGGED_TOOLBAR_ENTRIES,
	TOOLBAR_MAXIMIZE_ENTRY,
	TOOLBAR_MINIMIZE_ENTRY,
} from '@bussiness/resources/common/toolbar.constants';
import {
	ComponentsFacade,
	WizardPage,
	WizardPageConfig,
} from '@bussiness/store/components/components-facade';
import { LoaderToggleAction } from '@bussiness/store/components/loader/loader.actions';
import { ToastSendMessageAction } from '@bussiness/store/components/toast/toast.actions';
import { AppFacade } from '@bussiness/store/features/app/app.facade';
import { AuthFacade } from '@bussiness/store/features/auth/auth-facade';
import { DeviceFacade } from '@bussiness/store/features/device/device.facade';
import { HintsFacade } from '@bussiness/store/features/hints/hints.facade';
import { UsersFacade } from '@bussiness/store/features/users/users-facade';
import { navigationAnimation } from '@components/animations/navigation.animation';
import { ThemeService } from '@components/modules/common/theme-switcher/theme.service';
import { ToastService } from '@components/modules/common/toast/toast.service';
import {
	PlcToolbarAction,
	PlcToolbarData,
	PlcToolbarEntry,
} from '@components/modules/layout/toolbar/toolbar.models';
import { NavigationService } from '@core/services/common/navigation.service';
import { PlcPlatform } from '@core/utils/platform.util';
import { TranslateService } from '@ngx-translate/core';

import { Environment } from '../environments/environment';

@Component({
	selector: 'plc-root',
	templateUrl: './app.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-root' },
	animations: [navigationAnimation],
})
export class AppComponent implements OnInit {
	@HostBinding('class') public get rootCssClass(): string {
		return this.menuUnfolded ? 'plc-root--full-height' : '';
	}

	public displayLoader$: Observable<boolean> =
		this.componentsFacade.loaderToggleAction$.pipe(
			map(({ display }: LoaderToggleAction) => display),
		);

	public volume$: Observable<number> = this.deviceFacade.volume$;
	public hints$: Observable<string[]> = this.hintsFacade.all$.pipe(
		map((hints: Hint[]) => hints.map(({ text }) => text)),
	);
	public loadReasons$: Observable<string[]> =
		this.componentsFacade.loaderReasons$;

	public menuUnfolded = false;
	public environment: string = Environment.name;
	public year: number = new Date().getFullYear();
	public wizardConfig$: Observable<WizardPageConfig> =
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map((routeEvent: NavigationEnd) => routeEvent.url),
			switchMap((route: string) =>
				this.componentsFacade.wizardConfig(route),
			),
		);

	public toolbarEntries: PlcToolbarEntry<PlcToolbarAction>[] =
		NOT_LOGGED_TOOLBAR_ENTRIES;

	public theme$: Observable<Theme> = this.usersFacade.preferences$.pipe(
		tap((preferences: Preferences) =>
			this.themeService.setTheme(preferences?.theme),
		),
		filter((preferences: Preferences) => !!preferences),
		map(({ theme }: Preferences) => theme),
	);

	public lang$: Observable<Lang> = this.usersFacade.preferences$.pipe(
		map(
			(preferences: Preferences) =>
				preferences?.lang || PreferencesConstants.defaultLang,
		),
		map((lang: Lang) => lang),
		tap((lang: Lang) => this.translateService.use(lang)),
	);

	public get toolbarEntries$(): Observable<
		PlcToolbarEntry<PlcToolbarAction>[]
	> {
		return this.usersFacade.current$.pipe(
			map((user: User | null) => {
				let entries = user
					? LOGGED_TOOLBAR_ENTRIES
					: NOT_LOGGED_TOOLBAR_ENTRIES;

				if (this._fullScreenModeEnabled)
					entries = entries.concat(TOOLBAR_MINIMIZE_ENTRY);
				else entries = entries.concat(TOOLBAR_MAXIMIZE_ENTRY);

				return entries;
			}),
		);
	}

	public get userToolbarData$(): Observable<PlcToolbarData> {
		return this.usersFacade.current$.pipe(
			map((user: User | null) => {
				if (!user) return null;
				const { name, avatarUrl, role } = user;
				return { name, avatarUrl, role };
			}),
		);
	}

	private get _fullScreenModeEnabled(): boolean {
		return this.platform.fullScreenIsToggled;
	}

	constructor(
		private themeService: ThemeService,
		private router: Router,
		private translateService: TranslateService,
		private toastService: ToastService,
		private navigationService: NavigationService,
		private usersFacade: UsersFacade,
		private authFacade: AuthFacade,
		private componentsFacade: ComponentsFacade,
		private deviceFacade: DeviceFacade,
		private hintsFacade: HintsFacade,
		private appFacade: AppFacade,
		private platform: PlcPlatform,
	) {}

	ngOnInit() {
		this.translateService.setDefaultLang(PreferencesConstants.defaultLang);

		this.componentsFacade.toastSendMessageAction$
			.pipe(
				tap(({ title, text, mode }: ToastSendMessageAction) => {
					if (mode === 'info')
						this.toastService.showInfo(text, title);
					else if (mode === 'warn')
						this.toastService.showWarning(text, title);
					else if (mode === 'error')
						this.toastService.showError(text, title);
				}),
			)
			.subscribe(() => EMPTY);

		this.appFacade.initialize();
	}

	public handleMenuActionPress(action: PlcToolbarAction): void {
		if (action === 'back') this.navigationService.back();
		else if (action === 'toggle') this.menuUnfolded = !this.menuUnfolded;
		else if (action === 'home')
			this.router.navigateByUrl(PlcPublicRoute.Home);
		else if (action === 'about')
			this.router.navigateByUrl(PlcPublicRoute.About);
		else if (action === 'sign-in')
			this.router.navigateByUrl(PlcPublicRoute.SignIn);
		else if (action === 'log-in')
			this.router.navigateByUrl(PlcPublicRoute.Login);
		else if (action === 'new-game')
			this.router.navigateByUrl(PlcPrivateRoute.NewGame);
		else if (action === 'search-game')
			this.router.navigateByUrl(PlcPrivateRoute.SearchGame);
		else if (action === 'logout') this.authFacade.logoutUser();
		else if (action === 'profile')
			this.router.navigateByUrl(PlcPrivateRoute.Profile);
		else if (action === 'settings')
			this.router.navigateByUrl(PlcPrivateRoute.ProfileEdit);
		else if (action === 'fullscreen' && !this.platform.isIOS)
			this.platform.requestFullScreen();
		else if (action === 'fullscreen' && this.platform.isIOS)
			this.router.navigateByUrl(PlcPublicRoute.IOSFullScreen);
		else if (action === 'exit-fullscreen') this.platform.exitFullScreen();
	}

	public changeTheme(theme: Theme): void {
		this.themeService.setTheme(theme);
		this.usersFacade.setTheme(theme);
	}

	public changeVolume(volume: string): void {
		this.usersFacade.setVolume(+volume);
	}

	public changeLang(lang: Lang): void {
		this.translateService.use(lang);
		this.usersFacade.setLang(lang);
	}

	public getRouterAnimation(): number {
		return this.navigationService.getNavigationIndex();
	}

	public hideWizard(pageName: WizardPage): void {
		this.componentsFacade.wizardHide(pageName);
	}
}
