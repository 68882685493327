import { EMPTY, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { PreferencesConstants } from '@api/models/preferences.constants';
import { Preferences } from '@api/models/preferences.entity';
import { PlcSound } from '@components/models/sound.models';
import {
	AudioFeature,
	AudioPlayOptions,
} from '@core/services/native/audio.feature';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { UsersSelectors } from '../users/users.selectors';
import {
	DeviceActions,
	DevicePlayAudioAction,
	DeviceStopAudioAction,
} from './device.actions';
import { DeviceAudio } from './device.reducer';
import { DeviceSelectors } from './device.selectors';

@Injectable()
export class DeviceEffects {
	public playAudio$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DeviceActions.playAudio),
			concatLatestFrom(({ id }: DevicePlayAudioAction) => [
				this.store.select(DeviceSelectors.audio(id)),
				this.store.select(DeviceSelectors.volume),
			]),
			concatMap(
				([action, audio, volume]: [
					DevicePlayAudioAction,
					DeviceAudio<PlcSound | string>,
					number,
				]) => {
					const { id, src } = audio;
					const { options } = action;
					const optionsWithVolume: AudioPlayOptions = {
						...options,
						volume,
					};
					this.audioFeature.play(id, src, optionsWithVolume);

					return EMPTY as Observable<Action>;
				},
			),
		);
	});

	public stopAudio$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DeviceActions.stopAudio),
			concatMap(({ id }: DeviceStopAudioAction) => {
				this.audioFeature.stop(id);
				return EMPTY as Observable<Action>;
			}),
		);
	});

	public stopAllAudio$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DeviceActions.stopAllAudios),
			concatLatestFrom(() => this.store.select(DeviceSelectors.audios)),
			concatMap(
				([, audios]: [never, DeviceAudio<PlcSound | string>[]]) => {
					audios.forEach(({ id }: DeviceAudio<PlcSound | string>) =>
						this.audioFeature.stop(id),
					);
					return EMPTY as Observable<Action>;
				},
			),
		);
	});

	public setInitialVolume$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DeviceActions.setInitialVolume),
			concatLatestFrom(() =>
				this.store.select(UsersSelectors.preferences),
			),
			map(([, preferences]: [never, Preferences]) => {
				const volume =
					preferences?.volume ?? PreferencesConstants.defaultVolume;
				return DeviceActions.setVolume({ volume });
			}),
		);
	});

	constructor(
		private actions$: Actions,
		private audioFeature: AudioFeature,
		private store: Store,
	) {}
}
