import { EMPTY, Observable } from 'rxjs';
import { concatMap, exhaustMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AlphabetSoup } from '@api/models/alphabet-soup.entity';
import { UpdateAlphabetSoupRequestType } from '@api/models/enums';
import { FixAlphabetSoupRequest } from '@api/models/fix-alphabet-soup.request';
import { ReboundAlphabetSoupV2Request } from '@api/models/rebound-alphabet-soup-v2.request';
import {
	AlphabetSoupService,
	AlphabetSoupUpdatePathVariables,
} from '@api/services/alphabet-soup.service';
import { AlphabetSoupRoundScore } from '@bussiness/custom-models/alphabet-soup-phase.custom-models';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { GamesSelectors } from '../../games.selectors';
import { AlphabetSoupRoundSelectors } from '../alphabet-soup-round/alphabet-soup-round.selectors';
import {
	AlphabetSoupActions,
	AlphabetSoupFixAction,
	AlphabetSoupReboundAction,
} from './alphabet-soup.actions';
import { AlphabetSoupSelectors } from './alphabet-soup.selectors';

@Injectable()
export class AlphabetSoupEffects {
	public rebound$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AlphabetSoupActions.rebound),
			concatLatestFrom((action: AlphabetSoupReboundAction) => [
				this.store.select(GamesSelectors.id),
				this.store.select(
					AlphabetSoupSelectors.current(action.roundId),
				),
				this.store.select(
					AlphabetSoupRoundSelectors.scores(action.roundId),
				),
			]),
			exhaustMap(
				([action, gameId, soup, scores]: [
					AlphabetSoupReboundAction,
					string,
					AlphabetSoup,
					AlphabetSoupRoundScore,
				]) => {
					const { roundId } = action;
					const { time } = scores;

					const path: AlphabetSoupUpdatePathVariables = {
						gameId,
						roundId,
						soupId: soup._id,
					};

					const body: ReboundAlphabetSoupV2Request = {
						_type: UpdateAlphabetSoupRequestType.Rebound,
						time,
					};

					return this.alphabetSoupService
						.updateV3(path, body)
						.pipe(concatMap(() => EMPTY as Observable<Action>));
				},
			),
		);
	});

	public fix$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AlphabetSoupActions.fix),
			concatLatestFrom(() => [this.store.select(GamesSelectors.id)]),
			exhaustMap(([action, gameId]: [AlphabetSoupFixAction, string]) => {
				const { roundId, soupId, answers, rebounds, turnOwner } =
					action;

				const path: AlphabetSoupUpdatePathVariables = {
					gameId,
					roundId,
					soupId,
				};

				const body: FixAlphabetSoupRequest = {
					_type: UpdateAlphabetSoupRequestType.Fix,
					answers,
					rebounds,
					turnOwner,
				};

				return this.alphabetSoupService
					.updateV3(path, body)
					.pipe(concatMap(() => EMPTY as Observable<Action>));
			}),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private alphabetSoupService: AlphabetSoupService,
	) {}
}
