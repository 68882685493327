import { exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Hint } from '@api/models/hint.entity';
import { HintsService } from '@api/services/hints.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { HintsActions } from './hints.actions';

@Injectable()
export class HintsEffects {
	public loadHints$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(HintsActions.load),
			exhaustMap(() => {
				return this.hintsService
					.list()
					.pipe(
						map((hints: Hint[]) =>
							HintsActions.loadSuccess({ hints }),
						),
					);
			}),
		);
	});

	constructor(
		private actions$: Actions,
		private hintsService: HintsService,
	) {}
}
