import { map } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TUTORIAL_GAME } from '@bussiness/custom-models/wizard.custom-models';
import {
	PlcGamePathsGamePickerParams,
	PlcGameRoute,
} from '@bussiness/resources/common/routes.constants';
import { GamesActions } from '@bussiness/store/features/games/games.actions';
import { StringUtils } from '@bussiness/utils/string.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { WizardActions } from './wizard.actions';

@Injectable()
export class WizardEffects {
	public tutorialStart$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(WizardActions.tutorialStart),
			map(() => {
				const { _id: gameId } = TUTORIAL_GAME;

				const params: PlcGamePathsGamePickerParams = {
					gameId,
				};
				const path =
					StringUtils.populatePath<PlcGamePathsGamePickerParams>(
						PlcGameRoute.RolePicker,
						params,
					);
				this.router.navigateByUrl(path);
				return GamesActions.getCurrentSuccess({ game: TUTORIAL_GAME });
			}),
		);
	});

	constructor(
		private actions$: Actions,
		private store: Store,
		private router: Router,
	) {}
}
